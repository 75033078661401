import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { SessionStorage } from "ngx-webstorage";

@Component({
  selector: "bas-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.scss"],
})
export class FooterComponent {
  constructor(private router: Router) {}
  @SessionStorage("auth") public user: any;
  public currentYear = new Date().getFullYear();
}
