import { Component } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { SettingsService } from "src/app/bas/settings/settings.service";
import { SharedService } from "../../services";

@Component({
  selector: "bas-privacy-dialog",
  templateUrl: "./privacy-dialog.component.html",
  styleUrls: ["./privacy-dialog.component.scss"],
})
export class PrivacyDialogComponent {
  public showToaster: boolean = false;
  public value;

  constructor(
    private settingsService: SettingsService,
    private sanitizer: DomSanitizer,
    private shared: SharedService
  ) {}
  ngOnInit() {
    this.showToast();
    this.getData();
  }

  getData() {
    this.shared.display(true);
    this.settingsService.get("privacy-policy/1").subscribe((res) => {
      this.shared.display(false);
      this.value = res.payload;
    });
  }
  showToast() {
    this.showToaster = true;
    setTimeout(() => {
      this.closeToaster();
    }, 5000);
  }

  closeToaster() {
    this.showToaster = false;
  }
  getTrustedHTML(updates): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(updates);
  }
}
