export const jobTypes = [
  { type: "All", value: "", sendValue: "All" },
  { type: "Associates", value: "ASSOCIATES", sendValue: "ASSOCIATES" },
  { type: "Driver", value: "DRIVER", sendValue: "DRIVER" },
  { type: "Security", value: "SECURITY", sendValue: "SECURITY" },
  { type: "Facility/House Keeping", value: "FACILITY", sendValue: "FACILITY" },
  { type: "Delivery Associates", value: "DELIVERY", sendValue: "DELIVERY" },
];

export const flagFilters = [
  { type: "All", value: "All" },
  { type: "Green", value: "GREEN" },
  { type: "Orange", value: "ORANGE" },
  { type: "Red", value: "RED" },
];

export const duration = [
  { type: "Custom", value: "custom" },
  // { type: 'Year', value: 'year' },
  { type: "Month", value: "month" },
  { type: "Quarter", value: "quarter" },
  { type: "Week", value: "week" },
];

export const monthArray = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export const weekDays = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

export const weekListText = [
  "1st Week",
  "2nd Week",
  "3rd Week",
  "4th Week",
  "5th Week",
  "6th Week",
];

export const quarterArray = [
  {
    month: "Jan Feb Mar",
    quarterInfo: ["Jan", "Feb", "Mar"],
    quarter: "Quarter 1",
    startDate: 1,
    endDate: 31,
    startMonth: 0,
    endMonth: 2,
  },
  {
    month: "Apr May Jun",
    quarterInfo: ["Apr", "May", "Jun"],
    quarter: "Quarter 2",
    startDate: 1,
    endDate: 30,
    startMonth: 3,
    endMonth: 5,
  },
  {
    month: "Jul Aug Sep",
    quarterInfo: ["Jul", "Aug", "Sep"],
    quarter: "Quarter 3",
    startDate: 1,
    endDate: 30,
    startMonth: 6,
    endMonth: 8,
  },
  {
    month: "Oct Nov Dec",
    quarterInfo: ["Oct", "Nov", "Dec"],
    quarter: "Quarter 4",
    startDate: 1,
    endDate: 31,
    startMonth: 9,
    endMonth: 11,
  },
];

export class AssociatesEnrollementRate {
  chartOptions = {
    chart: {
      type: "area",
    },
    title: {
      text: `Associate's Enrollment`,
      align: "left",
      x: 0,
      style: {
        color: "#5baaff91",
      },
    },
    xAxis: {
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      crosshair: {
        width: 3,
        color: "#5baaff91",
      },
      tickmarkPlacement: "on",
      startOnTick: true,
    },
    yAxis: {
      title: {
        text: null,
      },
    },
    credits: {
      enabled: false,
    },
    plotOptions: {
      area: {
        marker: {
          enabled: false,
          symbol: "circle",
          radius: 2,
          states: {
            hover: {
              enabled: true,
            },
          },
        },
      },
      series: {
        animation: {
          duration: 2300,
        },
      },
    },
    series: [
      {
        name: "Year",
        data: [],
        color: "#5baaff91",
        showInLegend: false,
      },
    ],
    lang: {
      noData: "No Data",
    },
    noData: {
      style: {
        fontWeight: "bold",
        fontSize: "15px",
        color: "#303030",
      },
    },
  };
}

export class AssociatesEnrollementChart {
  config = {
    series: [
      {
        name: "Onboarded",
        data: [],
      },
      {
        name: "month",
        data: [],
      },
      {
        name: "week",
        data: [],
      },
    ],
    colors: ["#4FC9DA"],
    chart: {
      type: "area",
      width: "100%",
      height: 300,
      sparkline: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
    },
    xaxis: {
      type: "category",
    },
    yaxis: {
      show: true,
      opposite: false,
    },
    labels: [],
    legend: {
      show: false,
    },
    grid: {
      xaxis: {
        lines: {
          show: false,
        },
      },
      yaxis: {
        lines: {
          show: false,
        },
      },
    },
    fill: {
      colors: ["#4FC9DA"],
    },
  };
}

export class HrisDailyStats {
  config = {
    series: [],
    chart: {
      type: "donut",
      width: 200,
      height: 200,
    },
    // colors: ["#3E97FF", "#ff4b55", "#50CD89", "#BF40BF"],

    colors: ["#3F4254"],

    legend: {
      show: false,
      position: "bottom",
    },
    dataLabels: {
      enabled: false,
    },
    // labels: [
    //   "Rehire Flag Yes",
    //   "Rehire Flag No",
    //   "New Associates",
    //   "Site Remapping",
    // ],
    labels: ["Daily Stats"],
    plotOptions: {
      pie: {
        customScale: 1.1,
        donut: {
          size: "75%",
          labels: {
            show: true,
            value: {
              offsetY: -8,
              fontSize: "20px",
              fontFamily: "Poppins",
              fontWeight: "bold",
            },
            total: {
              show: true,
              label: "",
              fontFamily: "Poppins",
              showAlways: true,
            },
          },
        },
      },
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  };
}

export class OnboardingDailyStats {
  config = {
    series: [],
    chart: {
      type: "donut",
      width: 200,
      height: 200,
    },
    colors: ["#1b84ffd9"],

    // colors: ["#6cba65", "#ff4b55", "#F6A21E"],

    legend: {
      show: false,
      position: "bottom",
    },
    dataLabels: {
      enabled: false,
    },
    labels: ["Onboarded Associates", "Red Flagged", "Ready for Onboarding"],
    // labels: ["Onboarded", "Red Flagged", "Ready for Onboarding"],
    plotOptions: {
      pie: {
        customScale: 1.1,
        donut: {
          size: "75%",
          labels: {
            show: true,
            value: {
              offsetY: -8,
              fontSize: "20px",
              fontFamily: "Poppins",
              fontWeight: "bold",
            },
            total: {
              show: true,
              label: "",
              fontFamily: "Poppins",
              showAlways: true,
            },
          },
        },
      },
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  };
}

export class RedFlagStatistics {
  config = {
    series: [],
    chart: {
      type: "donut",
      width: 200,
      height: 180,
      sparkline: {
        enabled: true,
      },
    },
    labels: ["Blocked Associates"],
    datasets: {
      borderRadius: 20,
    },
    colors: ["#ff4b55"],
    plotOptions: {
      pie: {
        expandOnClick: false,
        startAngle: -90,
        endAngle: 90,
        donut: {
          size: "70%",
          labels: {
            show: true,
            name: {
              offsetY: -10,
              color: "green",
              label: "Total",
            },
            value: {
              offsetY: -45,
              fontSize: "20px",
              fontWeight: "bold",
              fontFamily: "Poppins",
            },
            total: {
              show: true,
              label: "Blocked Associates",
              fontSize: "10px",
              fontFamily: "Poppins",
              color: "#cccccc",
            },
          },
        },
      },
    },
    legend: {
      show: false,
      position: "bottom",
    },
    grid: {
      padding: {
        bottom: -50,
      },
    },
    dataLabels: {
      enabled: false,
    },
  };
}

export class hrisStatsData {
  config = {
    series: [100],
    chart: {
      type: "donut",
      width: 200,
      height: 180,
      sparkline: {
        enabled: true,
      },
    },
    labels: ["Blocked Associates"],
    datasets: {
      borderRadius: 20,
    },
    colors: ["#ff4b55"],
    plotOptions: {
      pie: {
        expandOnClick: false,
        startAngle: 0,
        endAngle: 360,
        donut: {
          size: "75%",
          labels: {
            show: true,
            name: {
              offsetY: -10,
              color: "green",
              label: "Total",
            },
            value: {
              offsetY: -45,
              fontSize: "20px",
              fontWeight: "bold",
              fontFamily: "Poppins",
            },
            total: {
              show: true,
              label: "Blocked Associates",
              fontSize: "10px",
              fontFamily: "Poppins",
              color: "#cccccc",
            },
          },
        },
      },
    },
    legend: {
      show: false,
      position: "bottom",
    },
    grid: {
      padding: {
        bottom: -50,
      },
    },
    dataLabels: {
      enabled: false,
    },
  };
}
