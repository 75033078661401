import {
  ChangeDetectorRef,
  Component,
  HostListener,
  OnInit,
} from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { SharedService } from "src/app/utils/services";
import { AppCommonService } from "../app-common.service";
import { ChangePassword } from "src/app/utils/models/common.model";
import { SessionStorage, SessionStorageService } from "ngx-webstorage";
import { MatSnackBar } from "@angular/material/snack-bar";

@Component({
  selector: "bas-set-password",
  templateUrl: "./set-password.component.html",
  styleUrls: ["./set-password.component.scss"],
})
export class SetPasswordComponent implements OnInit {
  @SessionStorage("mobileForOtp") public mobileForOtp: any;
  @SessionStorage("whichPage") public whichPage: any;
  @SessionStorage("auth") public authToken;

  public setpasswordForm: UntypedFormGroup;
  public userMobile;
  public barLabel: string = "Password strength:";
  public myColors = ["#DD2C00", "#FF6D00", "#FFD600", "#AEEA00", "#00C853"];
  public thresholds = [90, 75, 45, 25];
  public strengthbar: boolean = false;
  public strength: boolean;
  public changePasswordData = new ChangePassword();
  public showPassword = false;
  public showCPassword = false;
  public flag = [];
  public userId;

  constructor(
    public formBuilder: UntypedFormBuilder,
    private sharedService: SharedService,
    private appCommonService: AppCommonService,
    private router: Router,
    private snackBar: MatSnackBar,
    private cdr: ChangeDetectorRef,
    private sessionStorageService: SessionStorageService,
    private activatedRoute: ActivatedRoute
  ) {
    this.activatedRoute.paramMap.subscribe((paramsId) => {
      if (paramsId && paramsId.get("id") !== null) {
        this.whichPage = "set-password";
        this.sharedService.whichPage = "set-password";
        this.userId = paramsId.get("id");
      }
    });

    this.setpasswordForm = this.formBuilder.group(
      {
        password: ["", [Validators.required]],
        cpassword: ["", [Validators.required]],
      },
      {
        validator: this.matchPassword("password", "cpassword"),
      }
    );

    if (
      !["forgot-password", "change-password", "set-password"].includes(
        this.whichPage
      )
    ) {
      this.router.navigateByUrl("/login");
    }

    this.userMobile = this.mobileForOtp;

    if (this.userMobile || this.userId) {
      if (this.userMobile)
        this.userMobile = "*****" + this.userMobile.substring(6, 10);
    } else {
      this.router.navigateByUrl("/login");
    }
  }

  ngOnInit() {}

  toggleInputType() {
    this.showPassword = !this.showPassword;
  }
  toggleInput() {
    this.showCPassword = !this.showCPassword;
  }
  onPasswordStrengthChanged(event) {
    this.flag = this.sharedService.flag;
    const flagstrength = this.flag.reduce((acc, cur) => acc + cur, 0);
    if (flagstrength != 4) {
      this.setpasswordForm.controls["password"].setErrors({ pattern: true });
    }
    this.strength = event < 10 ? true : false;
    if (event < 10) {
      this.setpasswordForm.controls["password"].setErrors({ weak: true });
    }
  }

  ngAfterViewChecked() {
    this.cdr.detectChanges();
  }

  watchChange() {
    this.strengthbar = true;
  }

  setPasswordSubmit() {
    this.sharedService.display(true);

    const body = {
      mobile: this.mobileForOtp,
      password: this.setpasswordForm.get("password").value,
    };

    if (this.userId && this.whichPage === "set-password") {
      delete body.mobile;
      body["userid"] = this.userId;
    }

    let apiURL = "";
    if (this.whichPage === "forgot-password") {
      apiURL = "forgotpassword";
    } else if (this.whichPage === "change-password") {
      apiURL = "changepassword";
    } else if (this.userId && this.whichPage === "set-password") {
      apiURL = "set/password";
    } else {
      this.sharedService.openSnackBar({ error: "Your Are Doing Wrong" });
    }

    this.appCommonService.post(apiURL, body).subscribe(
      (response) => {
        this.sharedService.display(false);
        if (response.success === true) {
          this.sharedService.openSnackBar({ success: response.message });

          sessionStorage.removeItem("mobileForOtp");
          this.sessionStorageService.clear("mobileForOtp");
          this.sessionStorageService.clear();
          sessionStorage.clear();
          this.sharedService.whichPage = "no-page";
          this.whichPage = "no-page";
          this.router.navigateByUrl("/login");
        } else {
          this.sharedService.openSnackBar({
            error: "Error while Updating Password",
          });
        }
      },
      (err) => {
        this.sharedService.display(false);
        let errorMessage = window["serverError"];
        // List of errors are recived as array, so combine them to show a single message;
        if (err && err.hasOwnProperty("error")) {
          errorMessage = err.error.message
            ? err.error.message
            : "Error while password reset, please try with different password";
        }
        this.sharedService.openSnackBar({ error: errorMessage });
      }
    );
  }

  matchPassword(password: string, cpassword: string) {
    return (formGroup: UntypedFormGroup) => {
      const control = formGroup.controls[password];
      const matchingControl = formGroup.controls[cpassword];

      if (matchingControl.errors && !matchingControl.errors.mustMatch) {
        // return if another validator has already found an error on the matchingControl
        return;
      }

      // set error on matchingControl if validation fails
      if (
        control.value !== "" &&
        matchingControl.value !== "" &&
        control.value !== matchingControl.value
      ) {
        matchingControl.setErrors({ mustMatch: true });
      } else {
        matchingControl.setErrors(null);
      }
    };
  }
  @HostListener("document:keydown", ["$event"])
  allowOnlyNumers(event: any) {
    if (event.keyCode === 91) {
      return false;
    }
  }
}
