/**
 * @class - user details
 */
export class Associates {
  public first_name: string;
  public last_name: string;
  public email: string;
  public agency: string;
  public city: string;
  public site: string;
  public govt_id_proof: string;
  public govt_id_proof_type: string;
  public role: string;
  public job_type: string;
  public passwordreset: boolean;
  public id: string;
  public phone: Number;
  public created_at: Date;
  public updated_at: Date;
  public deleted: boolean;
  public deleteddate: Date;
  public isDelete: boolean;
  public flag: string;
  public external_id: string;
  public created_by: string;
  public org_name: string;
  public registration_status: string;
  public business_title: string;
  public transaction: string;
  public onboarding_date: Date;
  constructor(d: any = {}) {
    this.first_name = d.first_name || "";
    this.last_name = d.last_name || "";
    this.email = d.email || "";
    this.agency = d.agency || "";
    this.city = d.city || "";
    this.site = d.site || "";
    this.govt_id_proof = d.govt_id_proof || "";
    this.govt_id_proof_type = d.govt_id_proof_type || "";
    this.role = d.role || "";
    this.job_type = d.job_type || "";
    this.passwordreset = d.hasOwnProperty("passwordreset")
      ? d.passwordreset
      : false;
    this.id = d.id || "-";
    this.phone = d.contact || "";
    this.created_at = d.created_at;
    this.updated_at = d.updated_at;
    this.onboarding_date = d.onboarding_date || "NA";
    this.deleted = d.hasOwnProperty("deleted") ? d.deleted : false;
    this.isDelete = d.hasOwnProperty("isDelete") ? d.isDelete : false;
    this.deleteddate = d.deleteddate;
    this.flag = d.hasOwnProperty("flag") ? d.flag.toLowerCase() : "";
    this.external_id = d.hasOwnProperty("external_id") ? d.external_id : "-";
    this.created_by = d.created_by || "";
    if (d.vendor) {
      this.org_name = d.vendor.org_name || "";
    }
    this.registration_status = d.registration_status || "";
    this.business_title = d.business_title || "";
    this.transaction = d.transaction || "NO";
  }
}

export class Seperate {
  public first_name: string;
  public last_name: string;
  public city: string;
  public site: string;
  public id: string;
  public phone: Number;
  public updated_date: Date;
  public updated_by: string;
  public flag: string;
  public type: string;
  public external_id: string;
  constructor(d: any = {}) {
    this.first_name = d.firstName || "";
    this.last_name = d.lastName || "";
    this.city = d.city || "";
    this.site = d.site || "";
    this.id = d.id || "-";
    this.phone = d.contact || "";
    this.updated_by = d.updatedBy || "";
    this.updated_date = d.updatedAt || "";
    this.type = d.type || "";
    this.flag = d.hasOwnProperty("flag") ? d.flag.toLowerCase() : "";
    this.external_id = d.hasOwnProperty("externalId") ? d.externalId : "-";
  }
}

export class InActiveAssociates {
  public first_name: string;
  public last_name: string;
  public contact: Number;
  public city: string;
  public created_at: Date;
  public created_by: string;
  public doj: string;
  public dol: string;
  public employee_id: string;
  public external_id: string;
  public flag: string;
  public govt_id_proof: string;
  public govt_id_proof_type: string;
  public id: string;
  public onboarding_date: Date;
  public rehire_flag: string;
  public site: string;
  public transaction: string;
  public updated_at: Date;
  public org_name: string;

  constructor(d: any = {}) {
    this.first_name = d.first_name || "";
    this.last_name = d.last_name || "";
    this.contact = d.contact || "";
    this.city = d.city || "";
    this.created_at = d.created_at;
    this.created_by = d.created_by || "";
    this.doj = d.doj || "";
    this.dol = d.dol || "";
    this.employee_id = d.employee_id || "";
    this.external_id = d.external_id || "";
    this.flag = d.flag || "";
    this.govt_id_proof = d.govt_id_proof || "";
    this.govt_id_proof_type = d.govt_id_proof_type || "";
    this.id = d.id || "";
    if (d.vendor) {
      this.org_name = d.vendor.org_name || "";
    }
    this.onboarding_date = d.onboarding_date;
    this.rehire_flag = d.rehire_flag || "";
    this.site = d.site || "";
    this.transaction = d.transaction || "NO";
    this.updated_at = d.updated_at;
  }
}
export class RedFlagAssociates {
  public first_name: string;
  public last_name: string;
  public id: string;
  public associate_id: string;
  public external_id: string;
  public contact: Number;
  public registered_site: string;
  public registered_city: string;
  public registered_agency: string;
  public registered_org_name: string;
  public registered_domain_name: string;
  public org_name: string;
  public domain_name: string;
  public blocked_count: string;
  public remark: string;
  public created_by: string;
  public created_at: Date;

  constructor(d: any = {}) {
    this.first_name = d.first_name || "";
    this.last_name = d.last_name || "";
    this.id = d.id || "-";
    this.associate_id = d.associate_id || "";
    this.external_id = d.current_external_id || "";
    this.contact = d.contact || "";
    this.registered_city = d.registered_city || "";
    this.registered_site = d.registered_site || "";
    this.registered_agency = d.registered_agency || "";
    this.registered_org_name = d.registered_org_name || "";
    this.registered_domain_name = d.registered_domain_name || "";
    this.org_name = d.org_name || "";
    this.domain_name = d.domain_name || "";
    this.remark = d.remark || "";
    this.blocked_count = d.blocked_count || "";
    this.created_by = d.created_by || "";
    this.created_at = d.created_at;
  }
}

export class FlagCounts {
  public orange: number;
  public red: number;
  public green: number;

  constructor(data: any = {}) {
    this.orange = data.orange || 0;
    this.red = data.red || 0;
    this.green = data.green || 0;
  }
}

export class TransactionHistory {
  public firstName: string;
  public lastName: string;
  public contact: Number;
  public externalId: string;
  public employeeId: string;
  public city: string;
  public site: string;
  public doj: string;
  public flag: string;
  public dol: string;
  public onboardingDate: Date;
  public lastUpdatedAt: string;
  public createdAt: string;

  constructor(d: any = {}) {
    this.firstName = d.firstName || "";
    this.lastName = d.lastName || "";
    this.contact = d.contact || "-";
    this.externalId = d.externalId || "";
    this.employeeId = d.employeeId || "";
    this.city = d.city || "";
    this.site = d.site || "";
    this.doj = d.doj || "";
    this.dol = d.dol || "";
    this.flag = d.flag || "";
    this.lastUpdatedAt = d.lastUpdatedAt || "";
    this.createdAt = d.createdAt || "";
    this.onboardingDate = d.onboardingDate || "NA";
  }
}

export class RedFlagTransactionHistory {
  public firstName: string;
  public lastName: string;
  public contact: Number;
  public externalId: string;
  public employeeId: string;
  public city: string;
  public site: string;
  public doj: string;
  public dol: string;
  public flag: string;
  public lastUpdatedAt: string;
  public onboardingDate: Date;
  public createdAt: string;

  constructor(d: any = {}) {
    this.firstName = d.first_name || "";
    this.lastName = d.last_name || "";
    this.contact = d.contact || "-";
    this.externalId = d.current_external_id || "";
    this.employeeId = d.associate_id || "";
    this.city = d.registered_city || "";
    this.site = d.registered_site || "";
    this.doj = d.doj || "";
    this.dol = d.dol || "";
    this.flag = d.flag || "";
    this.lastUpdatedAt = d.updated_at || "";
    this.createdAt = d.created_at || "";
    this.onboardingDate = d.onboardingDate || "NA";
  }
}
export class Associate {
  public id: string;
  public first_name: string;
  public last_name: string;
  public agency: string;
  public city: string;
  public site: string;
  public govt_id_proof: string;
  public govt_id_proof_type: string;
  public job_type: string;
  public contact: Number;
  public flag: string;
  public external_id: string;
  public remark: string;
  // public left_finger: Array<any>;
  // public right_finger: Array<any>;

  constructor(d: any = {}) {
    this.id = d.hasOwnProperty("id") ? d.id : "";
    this.first_name = d.first_name || "";
    this.last_name = d.last_name || "";
    this.agency = d.agency || "";
    this.city = d.city || "";
    this.site = d.site || "";
    this.govt_id_proof = d.govt_id_proof || "";
    this.govt_id_proof_type = d.govt_id_proof_type || "";
    this.job_type = d.job_type || "";
    this.contact = d.contact || "";
    this.flag = d.hasOwnProperty("flag") ? d.flag.toLowerCase() : "";
    this.external_id = d.hasOwnProperty("external_id") ? d.external_id : "";
    this.remark = d.remark || "";
    // this.left_finger = d.left_finger || [];
    // this.right_finger = d.right_finger || [];
  }
}

/**
 * @class - Master modules's sub-modules
 */
export class AssociateTabs {
  public associates: boolean;

  constructor(d: any = {}) {
    this.associates = d.hasOwnProperty("associates") ? d.associates : false;
  }
}

export class VendorTabs {
  public vendors: boolean;

  constructor(d: any = {}) {
    this.vendors = d.hasOwnProperty("vendors") ? d.vendors : false;
  }
}
