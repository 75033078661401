import { Component, ViewChild } from "@angular/core";
import { UntypedFormGroup, FormBuilder, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { CKEditorComponent } from "ng2-ckeditor";
import { SharedService } from "../../../../app/utils/services";
import { SettingsService } from "../../settings/settings.service";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

@Component({
  selector: "bas-prepare-privacy",
  templateUrl: "./prepare-privacy.component.html",
  styleUrl: "./prepare-privacy.component.scss",
})
export class PreparePrivacyComponent {
  public Editor = ClassicEditor;
  public privacyForm: UntypedFormGroup;
  privacycontent: string = "";
  log: string = "";
  @ViewChild("myckeditor") ckeditor: CKEditorComponent;
  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private settingsService: SettingsService,
    public sharedService: SharedService
  ) {
    this.privacyForm = this.formBuilder.group({
      title: ["", [Validators.required]],
    });
  }

  ngOnInit() {
    this.getEditDetails();
  }

  getEditDetails() {
    this.settingsService.get(`privacy-policy/1`).subscribe((res) => {
      this.privacyForm.patchValue({
        title: res.payload.title,
      });
      this.privacycontent = res.payload.description;
    });
  }

  onSave() {
    this.settingsService
      .put(`privacy-policy/1`, {
        title: this.privacyForm.value.title,
        description: this.privacycontent,
      })
      .subscribe(
        (res) => {
          if (res.success) {
            this.sharedService.openSnackBar({ success: res.message });
            this.router.navigateByUrl("/privacy-setting/privacy");
          }
        },
        (err) => {
          this.sharedService.openSnackBar({ error: err.error.message });
        }
      );
  }

  onCancel() {
    this.router.navigateByUrl("/privacy-setting/privacy");
  }
}
