import { Component } from "@angular/core";
import { UsersService } from "../../users/users.service";
import { SessionStorage } from "ngx-webstorage";
import { SharedService } from "src/app/utils/services";

@Component({
  selector: "bas-mysites",
  templateUrl: "./mysites.component.html",
  styleUrls: ["./mysites.component.scss"],
})
export class MysitesComponent {
  @SessionStorage("auth") public authDetails;
  public sites = [];
  public unselectedSites = [];
  public isEditable = true;

  constructor(
    private usersService: UsersService,
    private sharedService: SharedService
  ) {}

  ngOnInit() {
    this.sharedService.inChangePassword.emit(false);
    this.sharedService.madeChanges.subscribe((res) => {
      if (res) {
        this.isEditable = true;
        this.getSites();
      }
    });
    this.getSites();
    this.sharedService.profileSites = true;
    this.sharedService.profileOverview = false;
    this.sharedService.profileEdit.subscribe((res) => {
      this.isEditable = res;
    });
    this.sharedService.edit.emit(false);
  }
  getSites() {
    this.usersService
      .post(`user/${this.authDetails.userDetails.id}`, {})
      .subscribe((res) => {
        this.sites = res.payload.site_code;
        this.unselectedSites = res.payload.availableSites;
      });
  }
  availableSiteChange(event) {
    if (event.checked) {
      if (this.unselectedSites.indexOf(event.source.name) >= 0) {
        this.sites.push(event.source.name);
        this.unselectedSites = this.unselectedSites.filter(
          (st) => st !== event.source.name
        );
        this.onValueChange();
      }
    }
  }
  assignSiteChange(event) {
    if (!event.checked) {
      if (this.sites.indexOf(event.source.name) >= 0) {
        this.unselectedSites.push(event.source.name);
        this.unselectedSites.sort();
        this.sites = this.sites.filter((st) => st !== event.source.name);
        this.onValueChange();
      }
    }
  }
  onValueChange() {
    let obj = {
      assigned: this.sites,
      available: this.unselectedSites,
    };
    this.sharedService.isDirty = true;
    this.sharedService.setProfileSiteData(obj);
  }
}
