import { NgModule } from "@angular/core";
import { CommonModule, DatePipe } from "@angular/common";
import { ConfirmationComponent } from "./components/confirmation/confirmation.component";
import { ValidateInputDirective } from "./directives/validate-input.directive";

// Components
import { ReportingManagerDialogComponent } from "./components/reporting-manager-dialog/reporting-manager-dialog.component";
import { FileUploadComponent } from "./components/file-upload/file-upload.component";
import { DownloadFilterDialogComponent } from "./components/download-filter-dialog/download-filter-dialog.component";

// Modules

import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { StatusComponent } from "./components";
import { NgxDaterangepickerMd } from "ngx-daterangepicker-material";

//plugin
import { NgSelectModule } from "@ng-select/ng-select";
import { DurationFilterDialogComponent } from "./components/duration-filter/duration-filter.component";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatDialogModule } from "@angular/material/dialog";
import { TransactionHistoryDialogComponent } from "./components/transaction-history-dialog/transaction-history-dialog.component";
import { NgxPaginationModule } from "ngx-pagination";
import { SitePopupComponent } from "./components/site-popup/site-popup.component";
import { MatSelectModule } from "@angular/material/select";
import { DeleteUserComponent } from "./components/delete-user/delete-user.component";
import { StatusComponentComponent } from "./components/status-component/status-component.component";
import { NgxSkeletonLoaderModule } from "ngx-skeleton-loader";
import { PrivacyDialogComponent } from "./components/privacy-dialog/privacy-dialog.component";

import { CheckboxModule } from "primeng/checkbox";

import { InputTextModule } from "primeng/inputtext";

import { PaginatorModule } from "primeng/paginator";

import { TableModule } from "primeng/table";

import { MessageService, ConfirmationService } from "primeng/api";
import { MatNativeDateModule } from "@angular/material/core";
import { MatDatepickerModule } from "@angular/material/datepicker";

@NgModule({
  declarations: [
    ConfirmationComponent,
    ValidateInputDirective,
    ReportingManagerDialogComponent,
    StatusComponent,
    FileUploadComponent,
    DownloadFilterDialogComponent,
    DurationFilterDialogComponent,
    TransactionHistoryDialogComponent,
    SitePopupComponent,
    DeleteUserComponent,
    StatusComponentComponent,
    PrivacyDialogComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatAutocompleteModule,
    MatDialogModule,
    NgxPaginationModule,
    // plugins
    NgSelectModule,
    MatSelectModule,
    NgxDaterangepickerMd.forRoot(),
    NgxSkeletonLoaderModule.forRoot(),
    //modules for primeng
    CheckboxModule,

    InputTextModule,

    PaginatorModule,

    TableModule,
    MatDatepickerModule,
    MatNativeDateModule,
  ],
  exports: [
    ValidateInputDirective,
    ConfirmationComponent,
    ReportingManagerDialogComponent,
    StatusComponent,
    FileUploadComponent,
  ],
  providers: [DatePipe, MessageService, ConfirmationService],
})
export class UtilsModule {}
