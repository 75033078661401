import {
  CUSTOM_ELEMENTS_SCHEMA,
  NgModule,
  NO_ERRORS_SCHEMA,
} from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { MatDialogModule } from "@angular/material/dialog";
import { MatListModule } from "@angular/material/list";
import { MatMenuModule } from "@angular/material/menu";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatCardModule } from "@angular/material/card";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatIconModule } from "@angular/material/icon";

import { NgSelectModule } from "@ng-select/ng-select";
import { AppCommonRoutingModule } from "./app-common-routing.module";
import { UtilsModule } from "../utils/utils.module";
import { AppCommonService } from "./app-common.service";

import { MyProfileModule } from "../bas/my-profile/my-profile.module";
import { FingerprintComponent } from "./fingerprint/fingerprint.component";
import { LoginV2Component } from "./login-v2/login-v2.component";
import { SetPasswordComponent } from "./set-password/set-password.component";
import { PasswordStrenthCheckerComponent } from "./password-strenth-checker/password-strenth-checker.component";
import { LoginV2VerifyComponent } from "./login-v2-verify/login-v2-verify.component";
import { ForgotPasswordV2Component } from "./forgot-password-v2/forgot-password-v2.component";
import { SidebarV2Component } from "./sidebar-v2/sidebar-v2.component";
import { HeaderV2Component } from "./header-v2/header-v2.component";
import { LayoutV2Component } from "./layout-v2/layout-v2.component";
import { AccountLockComponent } from "./account-lock/account-lock.component";
import { PageNotFoundComponent } from "./page-not-found/page-not-found.component";
import { ServerMaintenanceComponent } from "./server-maintenance/server-maintenance.component";
import { FooterComponent } from "./footer/footer.component";
import { NgxSkeletonLoaderModule } from "ngx-skeleton-loader";
import { PrivacySettingsModule } from "../bas/privacy-settings/privacy-settings.module";
import { MigrationPopupComponent } from "./migration-popup/migration-popup.component";
import { DeleteUserComponent } from "./delete-user/delete-user.component";
import { MatSelectModule } from "@angular/material/select";

@NgModule({
  declarations: [
    LoginV2Component,
    LayoutV2Component,
    HeaderV2Component,
    SidebarV2Component,
    FingerprintComponent,
    DeleteUserComponent,
    SetPasswordComponent,
    PasswordStrenthCheckerComponent,
    LoginV2VerifyComponent,
    ForgotPasswordV2Component,
    AccountLockComponent,
    PageNotFoundComponent,
    ServerMaintenanceComponent,
    FooterComponent,
    MigrationPopupComponent,
  ],
  imports: [
    CommonModule,
    AppCommonRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    MyProfileModule,
    PrivacySettingsModule,
    UtilsModule,
    MatDialogModule,
    MatIconModule,
    MatListModule,
    MatMenuModule,
    MatSidenavModule,
    MatSnackBarModule,
    MatSelectModule,
    NgSelectModule,
    MatCardModule,
    NgxSkeletonLoaderModule.forRoot(),
  ],
  exports: [FingerprintComponent],
  providers: [AppCommonService],
  schemas: [NO_ERRORS_SCHEMA],
})
export class AppCommonModule {}
