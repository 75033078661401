import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { LoginV2Component } from "./login-v2/login-v2.component";
import { LoginV2VerifyComponent } from "./login-v2-verify/login-v2-verify.component";
import { SetPasswordComponent } from "./set-password/set-password.component";
import { ServerMaintenanceComponent } from "./server-maintenance/server-maintenance.component";
import { DeleteUserComponent } from "./delete-user/delete-user.component";
import { PrivacySettingsComponent } from "../bas/privacy-settings/privacy-settings.component";

const routes: Routes = [
  { path: "", redirectTo: "login", pathMatch: "full" },
  { path: "login", component: LoginV2Component },

  { path: "otp-verify", component: LoginV2VerifyComponent },
  { path: "set-password", component: SetPasswordComponent },
  { path: "set-password/:id", component: SetPasswordComponent },
  { path: "server-maintenance", component: ServerMaintenanceComponent },
  { path: "delete-user", component: DeleteUserComponent },
  { path: "privacy-policy", component: PrivacySettingsComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AppCommonRoutingModule {}
