import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
  selector: "app-status",
  templateUrl: "./status.component.html",
  styleUrls: ["./status.component.scss"],
})
export class StatusComponent implements OnInit {
  public message;
  public emptyList = [];
  public statusMessage;
  constructor(
    private dialogRef: MatDialogRef<StatusComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    if (this.data.emptyColumnsList) {
      this.statusMessage = {
        emptyList: this.data.emptyColumnsList,
        notFoundList: this.data.notFoundList,
        rehireFlagFailedList: this.data.rehireFlagFailedList,
        dolFailedList: this.data.dolFailedList,
        wrongDolMessage: this.data.wrongDolMessage,
      };
    }
  }

  ngOnInit() {}

  closeModal(status) {
    this.dialogRef.close(status);
  }
}
