import {
  Component,
  OnInit,
  HostListener,
  ViewChild,
  AfterViewInit,
} from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { Router, RoutesRecognized } from "@angular/router";
import { SessionStorage, SessionStorageService } from "ngx-webstorage";
import { SharedService } from "src/app/utils/services";
import { AppCommonService } from "../app-common.service";
import { Auth, UpdateSession } from "src/app/utils/models/";
import { DialogComponent } from "src/app/bas/my-profile/dialog/dialog.component";
import { filter, pairwise } from "rxjs/operators";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MatDialog } from "@angular/material/dialog";

@Component({
  selector: "bas-login-v2-verify",
  templateUrl: "./login-v2-verify.component.html",
  styleUrls: ["./login-v2-verify.component.scss"],
})
export class LoginV2VerifyComponent implements OnInit, AfterViewInit {
  @ViewChild("otp4") private otp4: any;

  @SessionStorage("auth") public authToken;
  @SessionStorage("wdw") public tabName: any;
  @SessionStorage("mod") public myModules: any;
  @SessionStorage("site_code") public site_code: any;
  @SessionStorage("mobileForOtp") public mobileForOtp: any;
  @SessionStorage("whichPage") public whichPage: any;
  @SessionStorage("loginTime") public loginTime: any;
  @SessionStorage("otp") public otp: any;
  public sessionObj: UpdateSession;
  public otpForm: UntypedFormGroup;
  public userMobile = "";
  private backspaceCounter = 0;
  public resendTimer;
  public show;
  public showTimer: boolean = false;
  public mobile;
  public otpfor;
  public showValue = true;

  constructor(
    public formBuilder: UntypedFormBuilder,
    private sharedService: SharedService,
    private appCommonService: AppCommonService,
    private sessionStorageService: SessionStorageService,
    private router: Router,
    private snackBar: MatSnackBar,
    public dialog: MatDialog
  ) {
    this.otpForm = this.formBuilder.group({
      otp1: ["", [Validators.required]],
      otp2: ["", [Validators.required]],
      otp3: ["", [Validators.required]],
      otp4: ["", [Validators.required]],
      otp5: ["", [Validators.required]],
      otp6: ["", [Validators.required]],
    });
  }

  ngOnInit(): void {
    this.appCommonService.post("otp?get=true", {}).subscribe((res) => {
      this.show = res.isOtpOnScreen;
    });
    if (
      !["login", "forgot-password", "change-password"].includes(this.whichPage)
    ) {
      this.router.navigateByUrl("/login");
    }
    this.otpfor = this.otp;

    this.userMobile = this.mobileForOtp;

    if (this.userMobile) {
      this.userMobile = "*****" + this.userMobile.substring(6, 10);
    } else {
      this.router.navigateByUrl("/login");
    }

    // clear all the session storage data
    if (this.whichPage == "login") {
      this.authToken = new Auth();
      this.sessionObj = new UpdateSession();
      const temp = this.mobileForOtp;
      const temp1 = this.whichPage;
      this.sessionStorageService.clear();
      sessionStorage.clear();
      setTimeout(() => {
        this.mobileForOtp = temp;
        this.whichPage = temp1;
      }, 0);
    }
  }

  ngAfterViewInit() {
    // to disable the otp fields except the first field
    this.otp4.nativeElement.disabled = true;
    this.otp4.nativeElement.previousSibling.disabled = true;
    this.otp4.nativeElement.previousSibling.previousSibling.disabled = true;
    this.otp4.nativeElement.nextSibling.disabled = true;
    this.otp4.nativeElement.nextSibling.nextSibling.disabled = true;
  }

  otpSubmit() {
    this.sharedService.display(true);
    const otp = {
      otp:
        this.otpForm.value.otp1 +
        this.otpForm.value.otp2 +
        this.otpForm.value.otp3 +
        this.otpForm.value.otp4 +
        this.otpForm.value.otp5 +
        this.otpForm.value.otp6,
      userid: this.mobileForOtp,
    };

    let bodyData = {
      phone: otp.userid,
      otp: otp.otp,
      platform: "web",
    };
    if (
      this.whichPage === "forgot-password" ||
      this.whichPage === "change-password"
    ) {
      bodyData["skipToken"] = true;
    }
    this.appCommonService.post("otp/verify", bodyData).subscribe(
      (response) => {
        if (response.success === true) {
          this.otp = response.payload.otp;
          this.sharedService.openSnackBar({
            success: "OTP Verified Successfully",
          });
          this.sharedService.loginVerify.next(true);

          if (this.whichPage === "login") {
            this.appCommonService.setData("signature", response.payload.token);
            this.sharedService.whichPage = "no-page";
            this.whichPage = "no-page";
            this.site_code = response.payload.user.site_code;
            this.authToken = new Auth(response.payload);
            this.appCommonService
              .get("user/" + response.payload.user.reporting_manager)
              .subscribe(
                (response) => {
                  if (response.success) {
                    this.authToken.user = {
                      ...this.authToken.userDetails,
                      _id: this.authToken.userDetails.id,
                      reporting_manager_details: {
                        _id: response.payload._id,
                        firstName: response.payload.firstname,
                        lastName: response.payload.lastname,
                        mobile: response.payload.mobile,
                      },
                    };
                    this.authToken = new Auth(this.authToken);
                  }
                },
                (err) => {
                  console.log(err);
                }
              );
            // assigning unique id to current browser tab
            this.tabName = window.name = `${
              this.authToken.token
            }-${new Date().getTime()}`;
            this.mobile = this.mobileForOtp;
            sessionStorage.removeItem("mobileForOtp");
            this.sessionStorageService.clear("mobileForOtp");
            this.createSession();
            this.loginTime = new Date();
          } else if (this.whichPage === "forgot-password") {
            this.whichPage = "forgot-password";
            this.router.navigateByUrl("/set-password");
          } else if (this.whichPage === "change-password") {
            //profile
            this.router.navigateByUrl("/set-password");
          }
        } else {
          this.sharedService.openSnackBar({ error: "Invalid OTP" });
        }
        this.sharedService.display(false);
      },
      (err) => {
        this.sharedService.display(false);
        this.sharedService.openSnackBar({
          error: err.hasOwnProperty("error")
            ? err.error.message
            : window["serverError"],
        });
      }
    );
  }

  /**
   * @method - request to resend the OTP
   */
  resendOTP() {
    let pageType;
    if (this.whichPage === "login") pageType = "Login";
    else if (this.whichPage === "forgot-password") pageType = "Forgot Password";
    else if (this.whichPage === "change-password") pageType = "Change Password";

    const userData = {
      mobile: this.mobileForOtp,
      type: pageType,
    };
    this.sharedService.display(true);
    this.appCommonService.post("send/otp", userData).subscribe(
      (response) => {
        if (response.success === true) {
          this.sharedService.openSnackBar({
            success: 'OTP has been sent to "' + this.mobileForOtp + '"',
          });
          this.resetOtpFields();
          this.timer(5);
        }
        this.sharedService.display(false);
      },
      (err) => {
        this.sharedService.openSnackBar({
          error: err.hasOwnProperty("error")
            ? err.error.message
            : window["serverError"],
        });
        this.sharedService.display(false);
      }
    );
  }

  /**
   * @method - to reset the otp fields
   */
  resetOtpFields() {
    this.otpForm.patchValue({
      otp1: "",
      otp2: "",
      otp3: "",
      otp4: "",
      otp5: "",
      otp6: "",
    });
  }

  /* update session data */
  createSession() {
    this.sessionObj.token = this.authToken.token;
    this.sessionObj.type = this.authToken.tokenType;
    this.sessionObj.userid = this.authToken.userDetails.userid;
    this.sessionObj.phone = this.authToken.userDetails.mobile;
    this.sessionObj.platform = "web";
    this.updateSession();
  }

  /* to update session */
  updateSession() {
    const body = {
      token: this.sessionObj.token,
      type: this.sessionObj.type,
      phone: this.sessionObj.phone,
      platform: this.sessionObj.platform,
      ipaddress: this.sessionObj.ipaddress,
    };
    this.appCommonService.put("session/log", body).subscribe(
      (response) => {
        if (response.success) {
          this.getModuleRoute();
        }
      },
      (err) => {
        this.sharedService.openSnackBar({
          error: err.hasOwnProperty("error")
            ? err.error.message
            : window["serverError"],
        });
      }
    );
  }

  /* get the module details available for the login user and route accordingly */
  getModuleRoute() {
    // to get the routing details
    this.appCommonService.get("users/modules").subscribe(
      (response) => {
        this.sharedService.display(false);
        if (response.success && response.payload.length > 0) {
          this.myModules = response.payload;
          if (
            this.authToken &&
            this.authToken.userDetails &&
            this.authToken.userDetails.hasOwnProperty("passwordreset") &&
            this.authToken.userDetails.passwordreset === true
          ) {
            this.sharedService.whichPage = "change-password";
            this.whichPage = "change-password";
            this.mobileForOtp = this.mobile;
            this.router.navigateByUrl("/set-password");
          } else {
            this.navigatePage(true);
          }
        } else {
          this.sharedService.openSnackBar({
            error: "You do not have access to any modules.",
          });
        }
      },
      (err) => {
        this.sharedService.openSnackBar({
          error: err.hasOwnProperty("error")
            ? err.error.message
            : window["serverError"],
        });
        this.sharedService.display(false);
      }
    );
  }

  /* Navigate to initial/home page */
  navigatePage(canNavigate?: boolean) {
    const routingList = this.appCommonService.getRoutingData();
    // filter the modules which has some permissions
    let initialModule = this.myModules.filter(
      (module) =>
        module.resources.length &&
        module.resources.filter((rsc) => rsc.permissions.length).length
    );

    if (initialModule.length) {
      let filteredModule;
      const modulenames = initialModule.map((val) => val.modulename);
      if (modulenames.includes("Dashboard")) {
        if (initialModule.length) {
          filteredModule = routingList.filter(
            (routingData) =>
              routingData.module_name.toLowerCase() === "dashboard"
          );
        }
      } else {
        filteredModule = routingList.filter(
          (routingData) =>
            routingData.module_name.toLowerCase() ===
            initialModule[0].modulename.toLowerCase()
        );
      }

      this.sharedService.landingPage = filteredModule[0].routerLink;
      if (canNavigate) {
        this.router.navigate([filteredModule[0].routerLink]);
      }
    }
  }

  timer(minute) {
    this.showTimer = true;
    let seconds: number = minute * 60;
    let textSec: any = "0";
    let statSec: number = 60;

    const prefix = minute < 10 ? "0" : "";

    const timer = setInterval(() => {
      seconds--;
      if (statSec != 0) statSec--;
      else statSec = 59;

      if (statSec < 10) {
        textSec = "0" + statSec;
      } else textSec = statSec;

      this.resendTimer = `${prefix}${Math.floor(seconds / 60)}:${textSec}`;

      if (seconds == 0) {
        this.showTimer = false;
        clearInterval(timer);
      }
    }, 1000);
  }

  // To handle the focusing on input
  @HostListener("document:keyup", ["$event"])
  handleInputFocus(event: any) {
    // keycodes
    // BACKSPACE - 8, LEFT ARROWS - 37
    // ZERO - 48, 96, NINE - 57, 105, RIGHT ARROW -39, TAB - 9
    this.backspaceCounter = event.keyCode === 8 ? ++this.backspaceCounter : 0;
    if (
      (event.keyCode === 8 && this.backspaceCounter === 2) ||
      event.keyCode === 37
    ) {
      const previousElement = event.srcElement.previousElementSibling;
      // check if previousElement exists
      if (previousElement !== null) {
        previousElement.focus();
        if (event.keyCode === 8 && this.backspaceCounter === 2) {
          this.backspaceCounter = 1;
          previousElement.value = "";
          event.srcElement.disabled = true;
        } else {
          this.backspaceCounter = 0;
        }
      }
    } else if (
      (event.keyCode >= 48 &&
        event.keyCode <= 57 &&
        !event.shiftKey &&
        event.target.value !== "") ||
      (event.keyCode >= 96 && event.keyCode <= 105 && !event.metaKey) ||
      event.keyCode === 39 ||
      event.keyCode === 229
    ) {
      // get the sibling element
      const nextElement = event.srcElement.nextElementSibling;
      if (nextElement !== null) {
        nextElement.disabled = false;
        nextElement.focus();
      }
    }
  }

  // To allow only required keys
  @HostListener("document:keydown", ["$event"])
  allowOnlyNumers(event: any) {
    // keycode
    // ZERO - 48, 96, NINE - 57, 105, DELETE - 46, 110, BACKSPACE - 8, TAB - 9, LEFT ARROWS - 37, RIGHT ARROW -39
    if (
      !(
        (event.keyCode >= 48 && event.keyCode <= 57 && !event.shiftKey) ||
        (event.keyCode >= 96 && event.keyCode <= 105 && !event.metaKey) ||
        (event.keyCode === 37 && event.keyCode === 39) ||
        event.keyCode === 8 ||
        (event.keyCode === 9 && event.value === "") ||
        event.keyCode === 46 ||
        event.keyCode === 110
      )
    ) {
      return false;
    }
  }

  onClick(event, formField) {
    if (event.key && event.key.length > 0) {
      this.otpForm.get(formField).setValue(event.key);
    }
  }
}
