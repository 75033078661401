import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sortname'
})
export class SortnamePipe implements PipeTransform {

  transform(value: Array<any>, ...args: any[]): any {
    const sortField=args[0];
    value.sort((a,b)=>{
      
      if(a[sortField]<b[sortField])
        {
          return -1;
        }  
        else if(a[sortField]>b[sortField]){
            return 1;
        }
        else{
          return 0;
        }
    
      })
      return value;
  }

}
