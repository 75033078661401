import { Component, OnInit } from "@angular/core";
import {
  NgForm,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { ActivatedRoute, Router, RoutesRecognized } from "@angular/router";
import { AppCommonService } from "../app-common.service";
import { patternValidators } from "src/app/utils/validators";
import { SharedService } from "src/app/utils/services";
import { ForgotPasswordV2Component } from "../forgot-password-v2/forgot-password-v2.component";
import { environment } from "src/environments/environment";
import { NoopScrollStrategy } from "@angular/cdk/overlay";
import { AccountLockComponent } from "../account-lock/account-lock.component";
import { ReCaptchaV3Service } from "ng-recaptcha";
import { filter, pairwise, switchMap } from "rxjs/operators";
import { SessionStorage, SessionStorageService } from "ngx-webstorage";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MatDialog } from "@angular/material/dialog";
import { Subscription, timer } from "rxjs";
import { PrivacyDialogComponent } from "src/app/utils/components/privacy-dialog/privacy-dialog.component";
import { MigrationPopupComponent } from "../migration-popup/migration-popup.component";
@Component({
  selector: "app-login-v2",
  templateUrl: "./login-v2.component.html",
  styleUrls: ["./login-v2.component.scss"],
})
export class LoginV2Component implements OnInit {
  @SessionStorage("auth") public authToken;
  @SessionStorage("mobileForOtp") public mobileForOtp: any;
  @SessionStorage("otp") public otp: any;
  @SessionStorage("whichPage") public whichPage: any;
  public loginForm: UntypedFormGroup;
  public showPassword = false;
  public siteKey = environment.siteKey;
  public isChecked: boolean = false;
  public recaptcha: any = " ";
  private singleExecutionSubscription: Subscription;
  public recentError?: { error: any };

  constructor(
    private appCommonService: AppCommonService,
    private sharedService: SharedService,
    public formBuilder: UntypedFormBuilder,
    private router: Router,
    private snackBar: MatSnackBar,
    public dialog: MatDialog,
    private reCaptchaV3Service: ReCaptchaV3Service,
    private sessionStorageService: SessionStorageService,
    public activatedRoute: ActivatedRoute,
    private recaptchaV3Service: ReCaptchaV3Service
  ) {
    if (this.authToken && this.router.url.includes("login")) {
      this.sessionStorageService.clear();
      sessionStorage.clear();
    }

    this.loginForm = this.formBuilder.group({
      mobile: [
        "",
        [
          Validators.required,
          // Validators.pattern(patternValidators().mobileNumberRegExp),
        ],
      ],
      password: ["", [Validators.required]],
      // recaptcha: ["", [Validators.required]],
      checkbox: [false, [Validators.requiredTrue]],
      // cb: [false, Validators.requiredTrue]
    });
    this.whichPage = "no-page";
  }

  public executeAction(action: string): void {
    if (this.singleExecutionSubscription) {
      this.singleExecutionSubscription.unsubscribe();
    }
    this.singleExecutionSubscription = this.recaptchaV3Service
      .execute(action)
      .subscribe(
        (token) => {
          this.recaptcha = token;
          this.recentError = undefined;
        },
        (error) => {
          this.recaptcha = "";
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          this.recentError = { error };
        }
      );
  }

  ngOnInit() {
    setTimeout(() => {
      !process.env.DEV_BASE_URL.includes("localhost") &&
        this.openMigrationDialog();
    }, 1000);
    this.executeAction("submit");
    if (this.router.url.includes("login")) {
      this.router.events
        .pipe(
          filter((evt: any) => evt instanceof RoutesRecognized),
          pairwise()
        )
        .subscribe((events: RoutesRecognized[]) => {
          if (
            events[1].urlAfterRedirects.includes("login") &&
            !events[0].urlAfterRedirects.includes("login") &&
            this.authToken &&
            this.authToken.token
          ) {
            window.history.forward();
          }
        });
    }
    sessionStorage.removeItem("mobileForOtp");
    this.sessionStorageService.clear("mobileForOtp");
  }
  checked(value) {
    this.isChecked = value;
  }
  clicked() {
    this.isChecked = !this.isChecked;
  }
  handleSuccess(event) {}

  openAccountlockDialog() {
    const dialogRef = this.dialog.open(AccountLockComponent, {
      panelClass: "dialog-box", // Add your custom panel class
      scrollStrategy: new NoopScrollStrategy(),
    });
    dialogRef.afterClosed().subscribe((result) => {});
  }
  openMigrationDialog() {
    const dialogRef = this.dialog.open(MigrationPopupComponent, {
      panelClass: "dialog-box",
      data: "login", // Add your custom panel class
      scrollStrategy: new NoopScrollStrategy(),
    });
    dialogRef.afterClosed().subscribe((result) => {});
  }
  openDialog() {
    const dialogRef = this.dialog.open(ForgotPasswordV2Component, {
      panelClass: "dialog-box", // Add your custom panel class
      scrollStrategy: new NoopScrollStrategy(),
    });
    dialogRef.afterClosed().subscribe((result) => {});
  }

  /* To show or hide the password by changing input type to 'text' to 'password' and vice-versa */
  toggleInputType() {
    this.showPassword = !this.showPassword;
  }

  LoginStep2() {
    this.sharedService.display(true);
    const loginData = this.loginForm.value;
    delete loginData["recaptcha"];
    const body = {
      phone: loginData.mobile,
      password: loginData.password,
      recaptcha: this.recaptcha,
    };
    this.appCommonService.post("login", body).subscribe(
      (response) => {
        this.sharedService.display(false);
        if (response.success === true) {
          let number = "*****" + this.loginForm.value.mobile.substring(6, 10);
          this.sharedService.openSnackBar({
            success: `OTP has been sent to "${number}"`,
          });

          this.appCommonService.setData("mobile", this.loginForm.value.mobile);
          this.mobileForOtp = this.loginForm.value.mobile;
          // sessionStorage.setItem('mobileForOtp', `${this.loginForm.value.mobile}`);
          this.sharedService.whichPage = "login";
          this.whichPage = "login";
          this.otp = response.payload.otp;
          // this.whichPage='/otp-verify'
          this.router.navigateByUrl("/otp-verify");
        } else {
          this.sharedService.openSnackBar({ error: "Invalid Credentials" });
        }
      },
      (err) => {
        if (err.block === true) {
          this.sharedService.display(false);
          this.openAccountlockDialog();
        } else {
          this.sharedService.display(false);
          this.sharedService.openSnackBar({
            error: err.hasOwnProperty("error")
              ? err.error.message
              : "Could not connect to server",
          });
        }
      }
    );
  }

  onLogin() {
    if (this.recaptcha) {
      this.LoginStep2();
    } else {
      alert("Recaptcha Error");
    }
  }
  filterPopUp(id) {
    let obj = {
      id: id,
      selectedTab: "associates",
    };
    const dialogRef = this.dialog.open(PrivacyDialogComponent, {
      width: "auto",
      height: "90vh",
      data: obj,
      backdropClass: "transaction-associate-backdrop",
      panelClass: "transaction-associate-dialog",
      autoFocus: false,
    });
  }
  ngOnDestroy() {
    if (this.singleExecutionSubscription) {
      this.singleExecutionSubscription.unsubscribe();
    }
  }
}
