import { Injectable } from "@angular/core";
import {
  HttpService,
  ErrorService,
} from "src/app/utils/services";
import { Observable, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { Subject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class SettingsService {
  private subject = new Subject<any>();

  public logId: any;
  constructor(
    private httpService: HttpService,
    private errService: ErrorService
  ) {}

  /**
   * @method - to get the data
   * @param url - api name
   * @param data - login credentials
   */

  /**
   * @method - to get the data
   * @param url - api name
   * @param data - login credentials
   */
  get(url: any): Observable<any> {
    return this.httpService.get(url).pipe(
      map((res: Response) => {
        return res.hasOwnProperty("body") ? res.body : res;
      }),
      catchError((error) => {
        this.errorHandler(error);
        return throwError(error.hasOwnProperty("error") ? error.error : error);
      })
    );
  }

  getEmail(
    url: any,
    isFM200Service?: boolean,
    isSdk?: boolean
  ): Observable<any> {
    return this.httpService.get(url, isFM200Service, isSdk).pipe(
      // return this.httpService.get(url,FM220Url , isSdk).pipe(

      map((res: Response) => {
        return res.hasOwnProperty("body") ? res.body : res;
      }),
      catchError((error) => {
        this.errorHandler(error);
        return throwError(error.hasOwnProperty("error") ? error.error : error);
      })
    );
  }

  /**
   * @method - to send the data
   * @param url - api name
   * @param data - login credentials
   */
  post(url: any, data: any): Observable<any> {
    return this.httpService.post(url, data).pipe(
      map((res: Response) => {
        return res.hasOwnProperty("body") ? res.body : res;
      }),
      catchError((error) => {
        this.errorHandler(error);
        return throwError(error.hasOwnProperty("error") ? error.error : error);
      })
    );
  }

  postEmail(url: any, data: any, isFM200Service?: boolean): Observable<any> {
    return this.httpService.post(url, data, isFM200Service).pipe(
      map((res: Response) => {
        return res.hasOwnProperty("body") ? res.body : res;
      }),
      catchError((error) => {
        this.errorHandler(error);
        return throwError(error.hasOwnProperty("error") ? error.error : error);
      })
    );
  }

  /**
   * @method - to update the data
   * @param url
   * @param data
   */
  put(url: any, data: any): Observable<any> {
    return this.httpService.put(url, data).pipe(
      map((res: Response) => {
        return res.hasOwnProperty("body") ? res.body : res;
      }),
      catchError((error) => {
        this.errorHandler(error);
        return throwError(error.hasOwnProperty("error") ? error.error : error);
      })
    );
  }

  putEmail(url: any, data: any, isFM200Service?: boolean): Observable<any> {
    return this.httpService.put(url, data, isFM200Service).pipe(
      map((res: Response) => {
        return res.hasOwnProperty("body") ? res.body : res;
      }),
      catchError((error) => {
        this.errorHandler(error);
        return throwError(error.hasOwnProperty("error") ? error.error : error);
      })
    );
  }

  /**
   * @method - to update the data
   * @param url
   * @param data
   */
  delete(url: any): Observable<any> {
    return this.httpService.delete(url).pipe(
      map((res: Response) => {
        return res.hasOwnProperty("body") ? res.body : res;
      }),
      catchError((error) => {
        this.errorHandler(error);
        return throwError(error.hasOwnProperty("error") ? error.error : error);
      })
    );
  }

  deleteEmail(url: any, isFM200Service?: boolean): Observable<any> {
    return this.httpService.delete(url, isFM200Service).pipe(
      map((res: Response) => {
        return res.hasOwnProperty("body") ? res.body : res;
      }),
      catchError((error) => {
        this.errorHandler(error);
        return throwError(error);
      })
    );
  }

  errorHandler(error: any) {
    this.errService.handleError(error);
  }
}
