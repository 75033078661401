import { Component, OnInit } from "@angular/core";
import { SessionStorage } from "ngx-webstorage";
import { Auth, PaginationClass, User } from "src/app/utils/models";
import { SharedService } from "src/app/utils/services";
import { DashboardService } from "../../dashboard/dashboard.service";
import { ThemePalette } from "@angular/material/core";
import { ProgressBarMode } from "@angular/material/progress-bar";
import { MyProfileService } from "../my-profile.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { patternValidators } from "src/app/utils/validators";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ConfirmationComponent } from "src/app/utils/components";
import { MatDialog } from "@angular/material/dialog";

@Component({
  selector: "bas-profile-layout-v2",
  templateUrl: "./profile-layout-v2.component.html",
  styleUrls: ["./profile-layout-v2.component.scss"],
})
export class ProfileLayoutV2Component implements OnInit {
  public pageData = new PaginationClass({ pageSize: 10 });
  @SessionStorage("mod") public userModules: any;
  @SessionStorage("auth") public auth: any;
  public profileForm: FormGroup;
  public selectedTabLabel = "Change Password";
  public user = new User();
  public hovered = false;
  public onboardCount;
  public userCount;
  public checked = false;
  public userData;
  public inChangePassword;
  public role;
  public canNavigate = true;
  public hideCount = false;
  public vendorName;
  color: ThemePalette = "primary";
  mode: ProgressBarMode = "determinate";
  value = 50;
  bufferValue = 75;

  constructor(
    public sharedService: SharedService,
    private dashboardService: DashboardService,
    private myProfileService: MyProfileService,
    private formBuilder: FormBuilder,
    private snackBar: MatSnackBar,
    private dialog: MatDialog
  ) {
    this.profileForm = this.formBuilder.group({
      firstname: ["", [Validators.required]],
      lastname: ["", [Validators.required]],
      mobile: [
        "",
        [
          Validators.required,
          Validators.pattern(patternValidators().mobileNumberRegExp),
        ],
      ],
      role: ["", [Validators.required]],
      vendor: ["", [Validators.required]],
    });
  }

  ngOnInit() {
    this.sharedService.inChangePassword.subscribe((res) => {
      this.inChangePassword = res;
    });
    this.role = this.auth.userDetails.role;
    this.sharedService.show = true;
    this.getOnboardCount();
    this.getUserCount();
    this.sharedService.edit.subscribe((res) => {
      this.checked = res;
    });
  }

  hover() {
    this.hovered = true;
  }
  out() {
    this.hovered = false;
  }
  editable() {
    this.checked = true;
    this.sharedService.profileEdit.emit(false);
  }
  canDeactivate() {
    return this.canNavigate;
  }
  getVendorDetails() {
    this.vendorName = [];
    this.myProfileService.get("vendor").subscribe((res) => {
      if (this.auth.userDetails.vendor) {
        this.vendorName = res.message.filter(
          (response) => response._id == this.auth.userDetails.vendor
        );

        this.vendorName = this.vendorName[0].organizationname;
        this.sharedService.vendorUpdate.emit(this.vendorName);
        this.sharedService.vendorName = this.vendorName;
      }
    });
  }
  confirmSaving() {
    let confirmMsg = `Are you sure to save the changes?`;
    const dialogRef = this.dialog.open(ConfirmationComponent, {
      autoFocus: false,
      disableClose: true,
      height: "200px",
      panelClass: "confirm-delete-dialog",
      backdropClass: "confirm-delete-backdrop",
      data: {
        title: "Save Changes",
        message: confirmMsg,
        buttonLableSubmit: "Confirm",
      },
    });

    dialogRef.afterClosed().subscribe((status: Boolean) => {
      if (status) {
        this.updateProfile();
      }
    });
  }
  updateProfile() {
    this.sharedService.display(true);
    if (this.sharedService.profileOverview) {
      this.userData;
      this.userData = this.sharedService.getProfileData();

      let apiUrl = "user/";
      let id = this.auth.userDetails.id;
      if (!this.userData["email"]) {
        delete this.userData["email"];
      }

      this.myProfileService.put(apiUrl + id, this.userData).subscribe(
        (response) => {
          if (response.success) {
            this.sharedService.profileEdit.emit(true);

            this.checked = false;
            this.auth.userDetails.firstname = this.userData.firstname;
            this.auth.userDetails.lastname = this.userData.lastname;
            this.auth.userDetails.email = this.userData.email;
            this.auth.userDetails.mobile = this.userData.mobile;
            this.auth.userDetails.role = this.userData.role;
            let user = this.auth.userDetails;
            delete this.auth.userDetails;
            this.auth.user = user;
            this.auth.user._id = user.id;
            this.auth = new Auth(this.auth);
            this.handleSuccess("Profile Details updated successfully");
          }
        },
        (error) => {
          // this.checked = false;
          this.handleError(error);
        }
      );
    } else if (this.sharedService.profileSites) {
      this.userData = this.auth.userDetails;

      let values = this.sharedService.getProfileSiteData();
      this.userData["availableSites"] = values.available;
      this.userData["site_code"] = values.assigned;
      if (this.auth.userDetails.role == "superadmin") {
        delete this.userData["vendor"];
      } else {
        if (this.auth.userDetails.vendor.vendorId) {
          this.userData["vendor"] = this.auth.userDetails.vendor.vendorId;
        } else {
          this.userData["vendor"] = this.auth.userDetails.vendor;
        }
      }

      let apiUrl = "user/";
      let id = this.auth.userDetails.id;
      if (!this.userData["email"]) {
        delete this.userData["email"];
      }

      this.myProfileService.put(apiUrl + id, this.userData).subscribe(
        (response) => {
          if (response.success) {
            this.getVendorDetails();
            this.sharedService.profileEdit.emit(true);
            this.checked = false;
            this.auth.userDetails.name = this.profileForm.value.name;

            this.auth.userDetails.firstname = this.userData.firstname;
            this.auth.userDetails.lastname = this.userData.lastname;
            this.auth.userDetails.mobile = this.userData.mobile;
            this.auth.userDetails.email = this.userData.email;
            this.auth.userDetails.role = this.userData.role;
            this.handleSuccess("Profile Details updated successfully");
          }
        },
        (error) => {
          this.checked = false;
          this.handleError(error);
        }
      );
    }
  }

  onCancel() {
    if (this.sharedService.isDirty) {
      this.confirmNavigate();
      this.sharedService.profileEdit.emit(false);
    } else {
      this.checked = false;
      this.sharedService.profileEdit.emit(true);
    }
  }

  confirmNavigate() {
    let confirmMsg = `You have unsaved changes! Are you sure to leave?`;
    const dialogRef = this.dialog.open(ConfirmationComponent, {
      autoFocus: false,
      disableClose: true,
      height: "200px",
      panelClass: "confirm-delete-dialog",
      backdropClass: "confirm-delete-backdrop",
      data: {
        title: "Confirmation",
        message: confirmMsg,
        buttonLableSubmit: "Confirm",
      },
    });

    dialogRef.afterClosed().subscribe((status: Boolean) => {
      if (status) {
        this.sharedService.madeChanges.emit(true);
        this.checked = false;
      }
    });
  }

  getUserCount() {
    this.dashboardService
      .get(`users/count/${this.auth.userDetails.id}`)
      .subscribe((res) => {
        this.userCount = res;
      });
  }

  getOnboardCount() {
    let request;
    if (!this.auth.userDetails.mobile) {
      this.auth.userDetails.mobile = "";
    }
    if (!this.auth.userDetails.email) {
      this.auth.userDetails.email = "";
    }
    request =
      "?userMobile=" +
      this.auth.userDetails.mobile +
      "&userEmail=" +
      this.auth.userDetails.email;
    this.dashboardService
      .get(`associates/count` + request, true)
      .subscribe((res) => {
        this.onboardCount = res;
        if (
          this.auth.userDetails.role == "superadmin" &&
          this.onboardCount == 0
        ) {
          this.hideCount = true;
        }
      });
  }

  downloadUsers() {
    this.sharedService.display(true);
    this.myProfileService
      .fileDownload(`users/download/${this.auth.userDetails.id}`, {})
      .subscribe(
        (res) => {
          this.sharedService.display(false);
          const currentDate = new Date()
            .toString()
            .split(" GMT")[0]
            .replace(/:/g, "-");
          const blob = new Blob([res], { type: "application/vnd.ms-excel" });
          const link = document.createElement("a");
          link.setAttribute("href", window.URL.createObjectURL(blob));
          link.setAttribute("download", `users.csv`);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          this.sharedService.display(false);
        },
        (err) => {
          this.sharedService.display(false);
        }
      );
  }

  handleSuccess(message: string) {
    this.sharedService.display(false);
    this.sharedService.openSnackBar({ success: message });
  }

  handleError(error: any) {
    this.sharedService.display(false);
    this.sharedService.openSnackBar({
      error: error.error?.message || window["serverError"],
    });
  }
}
