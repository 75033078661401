import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { ChangePasswordV2Component } from "./change-password-v2/change-password-v2.component";
import { ProfileLayoutV2Component } from "./profile-layout-v2/profile-layout-v2.component";
import { ProfileV2Component } from "./profile-v2/profile-v2.component";
import { MysitesComponent } from "./mysites/mysites.component";

const routes: Routes = [
  {
    path: "",
    component: ProfileLayoutV2Component,
    children: [
      { path: "", redirectTo: "my-profile", pathMatch: "full" },
      { path: "my-profile", component: ProfileV2Component },
      { path: "change-password", component: ChangePasswordV2Component },
      { path: "my-sites", component: MysitesComponent },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class MyProfileRoutingModule {}
