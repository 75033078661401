import {
  CUSTOM_ELEMENTS_SCHEMA,
  NO_ERRORS_SCHEMA,
  NgModule,
} from "@angular/core";
import { CommonModule } from "@angular/common";

import { ChangeLogsComponent } from "./change-logs/change-logs.component";
import { PrivacySettingsRoutingModule } from "./privacy-settings-routing.module";
import { PrepareLogsComponent } from "./change-logs/prepare-logs/prepare-logs.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatIconModule } from "@angular/material/icon";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { NgxDaterangepickerMd } from "ngx-daterangepicker-material";
import { NgxPaginationModule } from "ngx-pagination";
import { NgxSkeletonLoaderModule } from "ngx-skeleton-loader";
import { PrivacySettingsComponent } from "./privacy-settings.component";
import { PreparePrivacyComponent } from "./prepare-privacy/prepare-privacy.component";
// import { CKEditorModule } from "ng2-ckeditor";
import { CKEditorModule } from "@ckeditor/ckeditor5-angular";
import { MatNativeDateModule } from "@angular/material/core";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";

@NgModule({
  declarations: [
    ChangeLogsComponent,
    PrepareLogsComponent,
    PrivacySettingsComponent,
    PreparePrivacyComponent,
  ],
  imports: [
    CommonModule,
    PrivacySettingsRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    NgxPaginationModule,
    MatSlideToggleModule,
    CKEditorModule,
    MatIconModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatFormFieldModule,
    MatInputModule,
    NgxDaterangepickerMd.forRoot(),
    NgxSkeletonLoaderModule.forRoot(),
  ],
  exports: [],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class PrivacySettingsModule {}
