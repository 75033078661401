import {
  Component,
  ChangeDetectorRef,
  OnInit,
  HostListener,
  OnDestroy,
} from "@angular/core";
import { Router } from "@angular/router";
import { SessionStorage, SessionStorageService } from "ngx-webstorage";
import { HttpService, SharedService } from "./utils/services";
import { AppCommonService } from "./common/app-common.service";
import { event } from "jquery";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  @SessionStorage("auth") public user;
  public loading = true;
  public isConnected = true;
  private unloadEventAdded = false;
  public showToaster: Boolean = false;
  public successMessage = "";
  public errorMessage = "";
  public timeout: ReturnType<typeof setTimeout> | null = null;
  constructor(
    public router: Router,
    public sharedService: SharedService,
    private changeDetector: ChangeDetectorRef
  ) {
    // check whether the user is online or offline
    this.isConnected = navigator.onLine;
    // this.connectionService.monitor().subscribe(isConnected => {
    //   this.isConnected = isConnected;
    //   this.changeDetector.detectChanges();
    // });

    this.timeout = setTimeout(() => this.closeToaster(), 5000);

    this.sharedService.snackbarEmitter.subscribe(({ success, error }) => {
      this.closeToaster();
      clearTimeout(this.timeout);
      if (success) {
        this.successMessage = success;
        this.timeout = setTimeout(() => this.closeToaster(), 5000);
        setTimeout(() => {
          this.showToaster = true;
        }, 0);
      }
      if (error) {
        this.errorMessage = error;
        this.timeout = setTimeout(() => this.closeToaster(), 5000);
        setTimeout(() => {
          this.showToaster = true;
        }, 0);
      }
    });
  }

  closeToaster() {
    this.showToaster = false;
    this.successMessage = this.errorMessage = "";
  }

  ngOnInit() {
    // redirect to login if the token is not there
    // if (!this.user) {
    //   this.router.navigateByUrl('/login');
    // }
    // show the loader if flag 'loading' is true
    this.addBeforeUnloadEvent();
    this.sharedService.status.subscribe((val: boolean) => {
      this.loading = val;
      this.changeDetector.detectChanges();
    });
  }
  addBeforeUnloadEvent() {
    if (!this.unloadEventAdded) {
      window.addEventListener("beforeunload", this.onBeforeUnload.bind(this));
      this.unloadEventAdded = true;
    }
  }

  onBeforeUnload(event: BeforeUnloadEvent) {
    const navigationType = (event.currentTarget as any).performance?.navigation;
    if (navigationType === 1) {
      return;
    }
    let mobile = this.user.userDetails.mobile;
    // navigator.sendBeacon(
    //   `https://bas-dev-api.msystechnologies.net/v2/session/logout?mobile=${mobile}`
    // );
  }
}
