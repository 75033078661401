import { PageNotFoundComponent } from "./common/page-not-found/page-not-found.component";
import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { LayoutV2Component } from "./common/layout-v2/layout-v2.component";
// import { LayoutComponent } from './common/layout/layout.component';
import { SharedService } from "./utils/services";

const routes: Routes = [
  {
    path: "",
    loadChildren: () =>
      import("./common/app-common.module").then((m) => m.AppCommonModule),
  },
  {
    path: "",
    // component: LayoutComponent,
    component: LayoutV2Component,
    children: [
      {
        path: "dashboard",
        loadChildren: () =>
          import("./bas/dashboard/dashboard.module").then(
            (m) => m.DashboardModule
          ),
      },
      {
        path: "manage-users",
        loadChildren: () =>
          import("./bas/users/users.module").then((m) => m.UsersModule),
      },
      {
        path: "manage-associates",
        loadChildren: () =>
          import("./bas/associates/associates.module").then(
            (m) => m.AssociatesModule
          ),
      },
      {
        path: "profile",
        loadChildren: () =>
          import("./bas/my-profile/my-profile.module").then(
            (m) => m.MyProfileModule
          ),
      },

      {
        path: "manage-vendors",
        loadChildren: () =>
          import("./bas/vendors/vendors.module").then((m) => m.VendorsModule),
      },
      {
        path: "manage-hris",
        loadChildren: () =>
          import("./bas/hris/hris.module").then((m) => m.HrisModule),
      },
      {
        path: "manage-hris",
        loadChildren: () =>
          import("./bas/hris/hris.module").then((m) => m.HrisModule),
      },
      {
        path: "settings",
        loadChildren: () =>
          import("./bas/settings/settings.module").then(
            (m) => m.SettingsModule
          ),
      },
      {
        path: "privacy-setting",
        loadChildren: () =>
          import("./bas/privacy-settings/privacy-settings.module").then(
            (m) => m.PrivacySettingsModule
          ),
      },
    ],
  },
  {
    path: "**",
    redirectTo: "404",
  },
  {
    path: "404",
    component: PageNotFoundComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule],
})
export class AppRoutingModule {}
