import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { StatusComponent } from "../status/status.component";
import { SharedService } from "../../services";

@Component({
  selector: "bas-status-component",
  templateUrl: "./status-component.component.html",
  styleUrls: ["./status-component.component.scss"],
})
export class StatusComponentComponent {
  public message: string;
  public isUploadStatus = false;
  public statusData = [];
  public inPage;
  public name;

  constructor(
    private dialogRef: MatDialogRef<StatusComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public shared: SharedService
  ) {
    this.statusData = this.data.hasOwnProperty("response")
      ? this.data.response
      : this.data;

    if (
      this.data.category === "csv-upload" &&
      (this.data.response.success === false ||
        this.data.response.hasOwnProperty("errors"))
    ) {
      this.statusData = this.data.response.hasOwnProperty("error")
        ? this.data.response.error.errors
        : this.data.response.errors;
      this.message = `Failed to upload ${
        this.statusData && this.statusData.length ? this.statusData.length : ""
      } data`;
    } else {
      this.message = "File uploaded successfully";
    }
  }
  ngOnInit() {
    this.inPage = this.shared.statusPage;
    if (this.inPage == "Users") {
      this.name = "User ID";
    } else if (this.inPage == "Vendors") {
      this.name = "Organization Name";
    } else if (this.inPage == "Sites") {
      this.name = "Site Code";
    } else if (this.inPage == "Approvals") {
      this.name = "Mobile Number";
    }
  }
  /**
   * @method - called when the modal is closed
   * @param param - status
   */
  closeModal(status) {
    this.dialogRef.close(status);
  }
}
