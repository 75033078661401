import { Component, Inject } from "@angular/core";
import { FormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { VendorsService } from "src/app/bas/vendors/vendors.service";
import { SharedService } from "../../services";
import { MatSnackBar } from "@angular/material/snack-bar";

@Component({
  selector: "bas-site-popup",
  templateUrl: "./site-popup.component.html",
  styleUrls: ["./site-popup.component.scss"],
})
export class SitePopupComponent {
  public typesArray = [];
  public selectedType = "";
  public cityArray = [];
  public statesArray = [];
  public stateSelect;
  public selectedState;
  public selectedCity;
  public states = [];
  public selectedRegion;
  public showToaster = false;
  public successMessage;
  public errorMessage;
  public regionCode: string[] = ["North", "West", "South", "East + Central"];
  // public regionCode: string[] = [
  //   "North",
  //   "West",
  //   "East",
  //   "South",
  //   "East + Central",
  //   "West + Central",
  //   "North + Central",
  //   "South + Central",
  // ];
  public editForm: UntypedFormGroup;
  constructor(
    private vendorService: VendorsService,
    public dialogRef: MatDialogRef<SitePopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private router: Router,
    public sharedService: SharedService,
    public snackBar: MatSnackBar,
    private formBuilder: FormBuilder
  ) {
    this.editForm = this.formBuilder.group({
      sitecode: [data.sitecode, [Validators.required]],
      state: [data.state, [Validators.required]],
      city: [data.city, [Validators.required]],
      region: [data.region, [Validators.required]],
      type: [data.type, [Validators.required]],
    });
    this.selectedState = data.state;
    this.selectedCity = data.city;
  }

  ngOnInit() {
    this.sharedService.siteedit(false);
    this.sharedService.sitecreate(false);

    this.getStationType();
    this.getState();
  }

  getStationType() {
    this.vendorService.get("station/types").subscribe(
      (res) => {
        if (res.success === true) {
          res.payload.forEach((response) => {
            this.typesArray.push(response.type);
          });
        } else {
        }
      },
      (err) => {}
    );
  }

  getState() {
    this.vendorService.get("states/IN").subscribe((res) => {
      if (res.success === true) {
        res.payload.forEach((response) => {
          this.states.push(response.name);
          let obj = {
            name: response.name,
            isoCode: response.isoCode,
          };
          this.statesArray.push(obj);
        });
      }
      if (this.data._id) {
        this.getEditCity();
      }
    });
  }

  getEditCity() {
    this.cityArray = [];
    let value = this.statesArray;
    let filtered = value.filter((res) => res.name == this.selectedState);
    this.vendorService
      .get(`cities/in/${filtered[0].isoCode}`)
      .subscribe((res) => {
        res.payload.forEach((response) => {
          this.cityArray.push(response.name);
        });
      });
  }

  getCity() {
    this.cityArray = [];
    let value = this.statesArray;
    let filtered = value.filter((res) => res.name == this.stateSelect);
    this.vendorService
      .get(`cities/in/${filtered[0].isoCode}`)
      .subscribe((res) => {
        res.payload.forEach((response) => {
          this.cityArray.push(response.name);
        });
      });
  }

  stateSelected(event) {
    this.stateSelect = event.value;
    this.getCity();
  }

  regionSelected(event) {}

  onSubmit() {
    let sitedata = this.editForm.value;

    if (this.data._id) {
      this.vendorService.put(`station/${this.data._id}`, sitedata).subscribe(
        (res) => {
          if (res.success) this.handleSuccessResponse("PUT");
        },
        (err) => {
          this.showError(err);
        }
      );
    } else {
      this.vendorService.post("station", this.editForm.value).subscribe(
        (res) => {
          if (res.success) this.handleSuccessResponse("POST");
        },
        (err) => {
          this.showError(err);
        }
      );
    }
  }

  handleSuccessResponse(method: String) {
    this.sharedService.openSnackBar({
      success:
        method === "PUT"
          ? "Site Details Updated Successfully"
          : "Site Details Created Successfully",
    });
    this.dialogRef.close(true);
  }

  showError(error: any) {
    this.sharedService.openSnackBar({
      error: error.error?.message || window["serverError"],
    });
  }

  close() {
    this.dialogRef.close();
  }
}
