import { Component } from "@angular/core";
import { SettingsService } from "../settings/settings.service";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { SharedService } from "../../../app/utils/services";
import { SessionStorage } from "ngx-webstorage";
import { Router } from "@angular/router";

@Component({
  selector: "bas-privacy-settings",
  templateUrl: "./privacy-settings.component.html",
  styleUrls: ["./privacy-settings.component.scss"],
})
export class PrivacySettingsComponent {
  public title: any;
  public description: any;
  public showData = false;
  hoveredRowIndex = false;
  public external = false;
  @SessionStorage("auth") public auth: any;
  constructor(
    private settingsService: SettingsService,
    private sanitizer: DomSanitizer,
    private shared: SharedService,
    private router: Router
  ) {
    if (!this.auth) {
      this.external = true;
    }
  }

  ngOnInit() {
    this.getData();
  }

  getData() {
    this.shared.display(true);
    this.settingsService.get("privacy-policy/1").subscribe((res) => {
      this.shared.display(false);
      this.title = res.payload.title;
      this.description = res.payload.description;
    });
  }

  getTrustedHTML(updates): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(updates);
  }

  confirmEdit() {
    this.router.navigateByUrl("privacy-setting/editPrivacy/1");
  }
}
