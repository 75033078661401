import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
} from "@angular/core";
import { onMainContentChange } from "../../utils/animations/animations";
import { AppCommonService } from "../app-common.service";
import { SessionStorage } from "ngx-webstorage";
import { Router } from "@angular/router";
import { BreakpointObserver, BreakpointState } from "@angular/cdk/layout";
import { MatSidenavModule } from "@angular/material/sidenav";

@Component({
  selector: "bas-layout-v2",
  templateUrl: "./layout-v2.component.html",
  styleUrls: ["./layout-v2.component.scss"],
  animations: [onMainContentChange],
})
export class LayoutV2Component implements OnInit {
  @SessionStorage("auth") public authToken;

  @ViewChild("leftSidenav") leftsidenav: any;

  public opened: boolean = false;
  public onSideNavChange: boolean;
  public classFlag;
  public count = 0;
  public side: boolean;
  public closed = false;
  public collapsed = false;
  public isOpen = false;

  constructor(
    private appCommonService: AppCommonService,
    private router: Router,
    private renderer: Renderer2,
    private observer: BreakpointObserver
  ) {
    this.observer
      .observe(["(max-width:769px)"])
      .subscribe((result: BreakpointState) => {
        if (result.matches) {
          this.appCommonService.setSideNav(true);
          this.classFlag = true;
        } else {
          this.appCommonService.setSideNav(false);
          this.classFlag = false;
        }
      });

    this.appCommonService.sideNavState$.subscribe((res) => {
      this.onSideNavChange = res;
    });
  }

  ngOnInit() {
    if (!this.authToken) {
      this.router.navigateByUrl("/login");
    }
  }

  receive($event: boolean) {
    this.collapsed = $event;
    this.leftsidenav.toggle();
  }

  received($event: boolean) {
    this.isOpen = $event;
    if (this.isOpen === true) {
      this.leftsidenav.toggle();
    }
  }

  click() {
    this.leftsidenav.toggle();
  }

  clicked() {
    if (this.closed === true) {
      this.closed = false;
    } else {
      this.closed = false;
    }
  }

  callMethods() {
    this.open(this.leftsidenav);
  }

  open(e: any) {
    e.toggle();
    this.closed = !this.closed;
  }

  callMethod() {
    this.openn(this.leftsidenav);
  }

  openn(e: any) {
    this.closed = false;
    e.toggle();
  }
}
