// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.strength {
  display: flex;
  flex-direction: row;
}

.strengthBar {
  display: flex;
  flex-grow: 1;
  list-style: none;
  margin: 0;
  padding: 0;
  vertical-align: 2px;
}

.point:last-of-type {
  margin: 0 !important;
}

.point {
  background: #ddd;
  border-radius: 2px;
  display: inline-block;
  height: 5px;
  margin-right: 1px;
  flex-grow: 1;
}

p {
  font-weight: 500;
  flex-grow: 0.1;
}`, "",{"version":3,"sources":["webpack://./src/app/common/password-strenth-checker/password-strenth-checker.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;AACF;;AAEA;EACE,aAAA;EACA,YAAA;EACA,gBAAA;EACA,SAAA;EACA,UAAA;EACA,mBAAA;AACF;;AAEA;EACE,oBAAA;AACF;;AAEA;EACE,gBAAA;EACA,kBAAA;EACA,qBAAA;EACA,WAAA;EACA,iBAAA;EACA,YAAA;AACF;;AAEA;EACE,gBAAA;EACA,cAAA;AACF","sourcesContent":[".strength {\n  display: flex;\n  flex-direction: row;\n}\n\n.strengthBar {\n  display: flex;\n  flex-grow: 1;\n  list-style: none;\n  margin: 0;\n  padding: 0;\n  vertical-align: 2px;\n}\n\n.point:last-of-type {\n  margin: 0 !important;\n}\n\n.point {\n  background: #ddd;\n  border-radius: 2px;\n  display: inline-block;\n  height: 5px;\n  margin-right: 1px;\n  flex-grow: 1;\n}\n\np {\n  font-weight: 500;\n  flex-grow: 0.1;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
