import { Component, OnInit } from "@angular/core";

@Component({
  selector: "bas-server-maintenance",
  templateUrl: "./server-maintenance.component.html",
  styleUrls: ["./server-maintenance.component.scss"],
})
export class ServerMaintenanceComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
