import { Component, OnInit } from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";

import { Router } from "@angular/router";
import { SessionStorage } from "ngx-webstorage";
import { SharedService } from "src/app/utils/services";
import { patternValidators } from "src/app/utils/validators/pattern.validator";
import { AppCommonService } from "../app-common.service";
import { MatDialogRef } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";

@Component({
  selector: "bas-forgot-password-v2",
  templateUrl: "./forgot-password-v2.component.html",
  styleUrls: ["./forgot-password-v2.component.scss"],
})
export class ForgotPasswordV2Component implements OnInit {
  @SessionStorage("mobileForOtp") public mobileForOtp: any;
  @SessionStorage("whichPage") public whichPage: any;
  @SessionStorage("otp") public otp: any;
  public forgotPasswordForm: UntypedFormGroup;

  constructor(
    public dialogRef: MatDialogRef<ForgotPasswordV2Component>,
    public formBuilder: UntypedFormBuilder,
    private sharedService: SharedService,
    private appCommonService: AppCommonService,
    private router: Router,
    private snackBar: MatSnackBar
  ) {
    this.forgotPasswordForm = this.formBuilder.group({
      mobile: [
        "",
        [
          Validators.required,
          Validators.pattern(patternValidators().mobileNumberRegExp),
        ],
      ],
    });
  }

  ngOnInit(): void {}

  /**
   * @method - send the user mobile number to which OTP has to be sent
   */

  forgotPasswordSubmit() {
    this.sharedService.display(true);
    const userData = {
      mobile: this.forgotPasswordForm.value.mobile,
      type: "Forgot password",
    };

    this.appCommonService.post("send/otp", userData).subscribe(
      (response) => {
        if (response.success === true) {
          this.appCommonService.setData(
            "mobile",
            this.forgotPasswordForm.value.mobile
          );
          this.mobileForOtp = this.forgotPasswordForm.value.mobile;
          // this.sharedService.toast(true);
          let number =
            "*****" + this.forgotPasswordForm.value.mobile.substring(6, 10);
          // this.sharedService.showSucces = `OTP has been sent o "${number}"`;
          this.sharedService.openSnackBar({
            success: `OTP has been sent to "${number}"`,
          });
          this.sharedService.whichPage = "forgot-password";
          this.whichPage = "forgot-password";
          this.close();
          this.otp = response.payload.otp;
          this.router.navigateByUrl("/otp-verify");
        } else {
          this.sharedService.openSnackBar({ error: "Error While Sending OTP" });
        }
        this.sharedService.display(false);
      },
      (err) => {
        this.sharedService.openSnackBar({
          error: err.hasOwnProperty("error")
            ? err.error.message
            : window["serverError"],
        });
        this.sharedService.display(false);
      }
    );
  }

  close() {
    this.dialogRef.close();
  }
}
