import {
  Component,
  OnInit,
  Input,
  ElementRef,
  ViewChild,
  EventEmitter,
  Output,
  HostListener,
} from "@angular/core";
import { Router, NavigationEnd } from "@angular/router";

import { FormBuilder } from "@angular/forms";
import { AppCommonService } from "../app-common.service";
import { ConfirmationComponent } from "src/app/utils/components/confirmation/confirmation.component";
import { SessionStorage, SessionStorageService } from "ngx-webstorage";
import { BreakpointObserver, BreakpointState } from "@angular/cdk/layout";
import {
  animateText,
  onSideNavChange,
} from "src/app/utils/animations/animations";
import { DashboardService } from "src/app/bas/dashboard/dashboard.service";
import { PaginationClass } from "src/app/utils/models";
import { MatIconModule } from "@angular/material/icon";
import { SharedService } from "src/app/utils/services";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MatButtonModule } from "@angular/material/button";

@Component({
  selector: "bas-header-v2",
  templateUrl: "./header-v2.component.html",
  styleUrls: ["./header-v2.component.scss"],
  animations: [onSideNavChange, animateText],
})
export class HeaderV2Component implements OnInit {
  @SessionStorage("auth") public user;
  @SessionStorage("uId") public viewableUser: any;
  @SessionStorage("pStatus") public isResetPassword: any;
  public pageData = new PaginationClass({ pageSize: 10 });
  @Output() collapsed = new EventEmitter<boolean>();

  public userName = "";
  public classFlag;
  public side = false;
  public userApprovalCount;
  public nav = true;
  public role;
  public count;
  public vendorName;
  public large = false;
  public larger = false;
  public myRoute = "/dashboard/approvals/approval";
  public clicked = false;
  public dropDownMenuList = [
    { menu_name: "My Profile" },
    // { menu_name: 'Change Password' },
    { menu_name: "Sign Out" },
  ];

  constructor(
    private router: Router,
    private appCommonService: AppCommonService,
    private sessionStorageService: SessionStorageService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private icons: MatIconModule,
    private dashboardService: DashboardService,
    private buttons: MatButtonModule,
    public sharedService: SharedService,
    private observer: BreakpointObserver
  ) {
    this.sharedService.vendorUpdate.subscribe((res) => {
      this.vendorName = res;
    });
    if (router.url == this.myRoute) {
      this.clicked = true;
    }
    this.observer
      .observe(["(max-width:769px)"])
      .subscribe((result: BreakpointState) => {
        if (result.matches) {
          this.classFlag = true;
        } else {
          this.classFlag = false;
        }
      });

    this.sharedService.getApprovalCount.subscribe((val) => {
      if (val) {
        this.dashboardService
          .post("users/approval/count", this.pageData)
          .subscribe((res) => {
            this.count = res.count;
          });
        if (this.count > 9 && this.count <= 99) {
          this.large = true;
        } else if (this.count > 99) {
          this.larger = true;
        }
      }
    });
  }

  ngOnInit() {
    this.appCommonService.setSideNav(true);

    this.userName =
      this.user && this.user.userDetails ? this.user.userDetails.name : "";
    this.role = this.user.userDetails.role;
    this.sharedService.getApprovalCount.next(true);
  }

  logout(): void {
    this.router.navigate([""]);
  }

  /* Action to be performed on menu items are clicked */
  onMenuClick(menuName: string) {
    switch (menuName) {
      case "Sign Out":
        const dialogRef = this.dialog.open(ConfirmationComponent, {
          autoFocus: false,
          disableClose: true,
          height: "200px",
          panelClass: "confirm-delete-dialog",
          backdropClass: "confirm-delete-backdrop",
          data: {
            title: "Sign Out",
            message: `Are you sure to Sign Out ?`,
            buttonLableSubmit: "Yes",
            buttonLableCancel: "No",
          },
        });

        dialogRef.afterClosed().subscribe((status: Boolean) => {
          if (status) {
            let mobile = this.user.userDetails.mobile;
            this.appCommonService
              .post(`session/logout?mobile=${mobile}`, {})
              .subscribe((res) => {});
            // Promise.all([this.logout()]).then(() => {
            // this.snackBar.open('Logged out successfully', 'okay', window['snackBarConfig']);
            this.sessionStorageService.clear();
            sessionStorage.clear();
            this.router.navigate([""]);
            // window.location.reload();
            // });
          }
        });
        break;
      case "My Profile":
        this.viewableUser = { user: {} };
        this.isResetPassword = false;
        this.router.navigateByUrl("/profile/my-profile");
        // this.router.navigateByUrl('/profile/change-password');
        break;
      case "My Sites":
        this.viewableUser = { user: {} };
        this.isResetPassword = false;
        this.router.navigateByUrl("/profile/my-sites");
        break;
      case "Change Password":
        this.viewableUser = { user: {} };
        this.isResetPassword = false;
        this.router.navigateByUrl("/profile/change-password");
        break;
    }
  }

  opened() {
    this.collapsed.emit(this.nav);
  }

  disappear() {
    if (this.myRoute) {
      this.sharedService.show = false;
    }
  }
}
