import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { ForgotPasswordV2Component } from "../forgot-password-v2/forgot-password-v2.component";

@Component({
  selector: "bas-migration-popup",
  templateUrl: "./migration-popup.component.html",
  styleUrls: ["./migration-popup.component.scss"],
})
export class MigrationPopupComponent {
  public page;

  constructor(
    public dialogRef: MatDialogRef<ForgotPasswordV2Component>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.page = this.data;
  }

  ngOnInit(): void {}

  close() {
    this.dialogRef.close();
  }
}
