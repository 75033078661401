import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { MyProfileRoutingModule } from "./my-profile-routing.module";
import { UtilsModule } from "src/app/utils/utils.module";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { DialogComponent } from "./dialog/dialog.component";
import { ProfileLayoutV2Component } from "./profile-layout-v2/profile-layout-v2.component";
import { ProfileV2Component } from "./profile-v2/profile-v2.component";
import { ChangePasswordV2Component } from "./change-password-v2/change-password-v2.component";
import { NgbProgressbarModule } from "@ng-bootstrap/ng-bootstrap";

import { MatBadgeModule } from "@angular/material/badge";
import { MatTabsModule } from "@angular/material/tabs";
import { MatInputModule } from "@angular/material/input";
import { MatSelectModule } from "@angular/material/select";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatCardModule } from "@angular/material/card";
import { MysitesComponent } from "./mysites/mysites.component";
import { MatGridListModule } from "@angular/material/grid-list";
import { MatCheckboxModule } from "@angular/material/checkbox";

@NgModule({
  declarations: [
    DialogComponent,
    ProfileLayoutV2Component,
    ProfileV2Component,
    ChangePasswordV2Component,
    MysitesComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MyProfileRoutingModule,
    UtilsModule,
    MatTabsModule,
    MatInputModule,
    MatSelectModule,
    MatProgressBarModule,
    NgbProgressbarModule,
    MatBadgeModule,
    MatCardModule,
    MatGridListModule,
    MatCheckboxModule,
  ],
  exports: [DialogComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class MyProfileModule {}
