import { DatePipe } from "@angular/common";
import { Component } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { Router } from "@angular/router";
import moment, { Moment } from "moment";
import { SessionStorage } from "ngx-webstorage";
import { ConfirmationComponent } from "src/app/utils/components";
import { SharedService } from "src/app/utils/services";
import { SettingsService } from "../../settings/settings.service";

@Component({
  selector: "bas-change-logs",
  templateUrl: "./change-logs.component.html",
  styleUrls: ["./change-logs.component.scss"],
})
export class ChangeLogsComponent {
  @SessionStorage("auth") public authDetails;
  public updatesAndFixes: any = [];
  public selected;
  public versions = [];
  public updated;
  public start = "";
  public end = "";
  public rangeLabel;
  public updatedBy;
  public showToaster = false;
  public successMessage;
  public errorMessage;
  public isEventLabel = false;
  selectedrange: { startDate: Moment; endDate: Moment };
  public firstDescription;
  public firstText;
  public locale;
  public firstDate;
  public firstVerion;
  public startSelected;
  public endSelected;
  startDates: any = new Date("2019-01-01");
  endDates: any = new Date();
  selectedValue: any = { startDate: null, endDate: null };
  ranges: any = {
    All: [moment("2019-01-01T00:00:00")],
    Today: [moment(), moment()],
    Yesterday: [moment().subtract(1, "days"), moment().subtract(1, "days")],
    // 'Last 7 Days': [moment().subtract(6, 'days'), moment()],
    "Last 30 Days": [moment().subtract(29, "days"), moment()],
    "This Month": [moment().startOf("month"), moment().endOf("month")],
    "Last Month": [
      moment().subtract(1, "month").startOf("month"),
      moment().subtract(1, "month").endOf("month"),
    ],
    "Last 3 Month": [
      moment().subtract(3, "month").startOf("month"),
      moment().subtract(1, "month").endOf("month"),
    ],
  };
  selectedRange = "Today: " + this.datePipe.transform(Date.now(), "MMM dd");

  constructor(
    private settingsService: SettingsService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private sharedService: SharedService,
    private sanitizer: DomSanitizer,
    public datePipe: DatePipe,
    private router: Router
  ) {}
  ngOnInit() {
    let startdate = moment("2019-01-01T00:00:00");
    this.selectedValue.startDate = startdate;
    this.selectedValue.endDate = moment();
    this.rangeLabel = "all";
    this.locale = {
      format: "MM/DD/YYYY", // could be 'YYYY-MM-DDTHH:mm:ss.SSSSZ'
      displayFormat: "MM/DD/YYYY",
      direction: "ltr",
      weekLabel: "W",
      separator: " - ",
      cancelLabel: "Cancel",
      applyLabel: "Apply",
      clearLabel: "Clear",
      customRangeLabel: "Custom Range",
      daysOfWeek: moment.weekdaysMin(),
      monthNames: moment.monthsShort(),
    };
    this.getChangeLogs();
    this.settingsService.logId = "";
  }

  getChangeLogs() {
    this.isEventLabel = false;
    this.versions = [];
    this.firstText = "";
    this.firstDescription = "";
    this.firstVerion = "";
    this.sharedService.display(true);
    let defaultStart = this.convertDateFormat(this.startDates);
    if (!this.start && this.end) {
      this.start = defaultStart;
    }
    let obj = {
      startdate: this.start,
      enddate: this.end,
    };
    this.settingsService.post("changelogs", obj).subscribe(
      (res) => {
        if (res.success == true) {
          this.sharedService.display(false);
          res.payload.rows.forEach((element) => {
            this.updatesAndFixes.push(element.updatesandfixes);
          });
          this.firstText =
            this.updatesAndFixes[this.updatesAndFixes.length - 1];

          res.payload.rows.forEach((element) => {
            element.createdAt = this.datePipe.transform(
              element.createdAt,
              "dd MMM yyyy"
            );
            element.updatedAt = this.datePipe.transform(
              element.updatedAt,
              "dd MMM yyyy"
            );
            let obj = {
              id: element._id,
              version: element.version,
              updates: element.updatesandfixes,
              description: element.description,
              createdAt: element.createdAt,
              updatedAt: element.updatedAt,
              updatedBy: element.updatedBy,
              createdBy: element.createdBy,
            };
            this.versions.push(obj);
          });
          this.versions = this.versions.reverse();
          this.firstVerion = this.versions[0].version;
          this.firstDescription = this.versions[0].description;
          this.firstDate = this.versions[0].createdAt;
          if (this.versions[0].updatedBy.firstname) {
            this.updatedBy = this.versions[0].updatedBy;
          } else {
            this.updatedBy = this.versions[0].createdBy;
          }

          this.updated = this.versions[0].updatedAt;
        }
      },
      (err) => {
        this.showError(err);
      }
    );
  }

  clicked(version) {
    this.selected = this.versions.filter((ver) => ver.version == version);
    this.selected = this.selected[0];
  }

  getTrustedHTML(updates): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(updates);
  }

  confirmDeletion(user) {
    let confirmMsg = `Are you sure to delete "${user.description}" ?`;
    const dialogRef = this.dialog.open(ConfirmationComponent, {
      autoFocus: false,
      disableClose: true,
      height: "200px",
      panelClass: "confirm-delete-dialog",
      backdropClass: "confirm-delete-backdrop",
      data: {
        title: `Delete Log`,
        message: confirmMsg,
      },
    });

    dialogRef.afterClosed().subscribe((status: Boolean) => {
      if (status) {
        this.deleteLog(user);
      }
    });
  }

  confirmEdit(version) {
    this.settingsService.logId = version.id;
    this.router.navigateByUrl(`privacy-setting/editLog/${version.id}`);
  }

  deleteLog(user) {
    this.sharedService.display(true);
    this.settingsService.delete(`changelog/${user.id}`).subscribe(
      (res) => {
        if (res.success) {
          this.handleSuccessResponse(res.message);
          this.getChangeLogs();
        }
      },
      (err) => {
        this.showError(err);
      }
    );
  }

  startDateChange(selectedDate: any) {
    const originalDateString = selectedDate.target.value;
    const convertedDateString = this.convertDateFormat(originalDateString);

    this.startSelected = convertedDateString;
    this.start = this.startSelected;
    let endValue = this.convertDateFormat(this.endDates);
    this.end = endValue;
    this.getChangeLogs();
  }

  endDateChange(selectedDate: any) {
    if (selectedDate.target.value) {
      const originalDateString = selectedDate.target.value;
      const convertedDateString = this.convertDateFormat(originalDateString);
      this.endSelected = convertedDateString;
      this.start = this.startSelected;
      this.end = this.endSelected;
      this.getChangeLogs();
    }
  }

  convertDateFormat(inputDate) {
    const dateObject = new Date(inputDate);
    const formattedDate = dateObject.toLocaleDateString("en-US", {
      month: "short",
      day: "numeric",
      year: "numeric",
    });
    return formattedDate;
  }

  choosedDate(date) {
    if (date.startDate && date.endDate) {
      this.start = this.datePipe.transform(date.startDate.$d);
      this.end = this.datePipe.transform(date.endDate.$d);
      let start = this.datePipe.transform(date.startDate.$d, "dd MMM yyyy");
      let end = this.datePipe.transform(
        date.endDate.$d.setDate(date.endDate.$d.getDate() - 1),
        "dd MMM yyyy"
      );
      if (!this.isEventLabel) {
        this.rangeLabel = start + "-" + end;
      }
      this.getChangeLogs();
    }
  }

  rangeClicked(event) {
    if (event.label) {
      this.isEventLabel = true;
    } else {
      this.isEventLabel = false;
    }

    this.start = this.datePipe.transform(event.dates[0].$d, "MMM dd");
    // this.end = this.datePipe.transform(event.dates[1].$d, "MMM dd");
    let val = event.dates[0].$d.setHours(
      event.dates[0].$d.getHours() - 5,
      event.dates[0].$d.getMinutes() - 30
    );
    this.end = this.datePipe.transform(val);
    if (event.label == "Today" || event.label == "Yesterday") {
      this.rangeLabel = event.label + ": " + this.end;
    } else {
      this.rangeLabel = event.label;
    }
    this.locale.displayFormat = this.rangeLabel;
  }

  handleSuccessResponse(message: any) {
    this.sharedService.display(false);
    this.sharedService.openSnackBar({ success: message });
  }

  showError(error: any) {
    this.sharedService.display(false);
    this.sharedService.openSnackBar({
      error: error.error?.message || window["serverError"],
    });
  }
}
