import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { SharedService } from "../../services";
import { SettingsService } from "src/app/bas/settings/settings.service";

@Component({
  selector: "bas-delete-user",
  templateUrl: "./delete-user.component.html",
  styleUrls: ["./delete-user.component.scss"],
})
export class DeleteUserComponent {
  public buttonLableSubmit = "Delete";
  public buttonLableCancel = "Cancel";
  public selectVendor = false;
  public slectConfirmation = true;
  public vendorCollections = [];

  public userID: string;
  constructor(
    private dialogRef: MatDialogRef<DeleteUserComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private service: SettingsService,
    private sharedService: SharedService
  ) {
    this.buttonLableSubmit = data.hasOwnProperty("buttonLableSubmit")
      ? data.buttonLableSubmit
      : this.buttonLableSubmit;
    this.buttonLableCancel = data.hasOwnProperty("buttonLableCancel")
      ? data.buttonLableCancel
      : this.buttonLableCancel;
  }

  closeModal(status: boolean) {
    this.dialogRef.close(status);
  }

  onSubmit() {
    this.service
      .deleteEmail(`associates/emails/delete/${this.data.id}`, true)
      .subscribe(
        (res) => {
          this.sharedService.display(false);
          this.dialogRef.close(true);
          this.sharedService.openSnackBar({
            success: "Email has been deleted successfully.",
          });
        },
        () => {
          this.sharedService.openSnackBar({
            error: "Error while deleting email.",
          });
        }
      );
  }
}
