import {
  Component,
  OnInit,
  OnDestroy,
  Output,
  EventEmitter,
} from "@angular/core";
import { Subscription } from "rxjs";
import {
  onSideNavChange,
  animateText,
} from "src/app/utils/animations/animations";
import { AppCommonService } from "../app-common.service";
import { Router } from "@angular/router";
import { SessionStorage } from "ngx-webstorage";
import { SharedService } from "src/app/utils/services";
import { BreakpointObserver, BreakpointState } from "@angular/cdk/layout";

@Component({
  selector: "bas-sidebar-v2",
  templateUrl: "./sidebar-v2.component.html",
  styleUrls: ["./sidebar-v2.component.scss"],
  animations: [onSideNavChange, animateText],
})
export class SidebarV2Component implements OnInit, OnDestroy {
  @SessionStorage("mod") public myModules: any;
  @SessionStorage("auth") public user: any;
  @Output() collap = new EventEmitter<boolean>();
  public sideNavState = false;
  public side = false;
  public linkText = false;
  public imgToggle = false;
  public role;
  public collapsed = true;
  public makeToggleActive = false;
  private stateSubscription: Subscription;
  public modules: Array<any> = [];
  public classFlag;
  constructor(
    private appCommonService: AppCommonService,
    private sharedService: SharedService,
    public router: Router,
    private observer: BreakpointObserver
  ) {
    this.observer
      .observe(["(max-width:769px)"])
      .subscribe((result: BreakpointState) => {
        if (result.matches) {
          this.classFlag = true;
        } else {
          this.classFlag = false;
        }
      });
  }

  ngOnInit() {
    this.role = this.user.userDetails.role;
    if (this.user && this.user.userDetails) {
      this.getModules();
      this.listenToSidenavStateChange();
    }
    this.observer.observe(["(max-width:769px)"]).subscribe((res) => {
      if (res.matches) {
        this.classFlag = true;
      } else {
        this.classFlag = false;
      }
    });
  }

  listenToSidenavStateChange() {
    this.stateSubscription = this.appCommonService
      .getSideNav()
      .subscribe((res) => {
        this.sideNavState = res.text;

        setTimeout(() => {
          this.linkText = this.sideNavState;
          if (this.linkText === false) {
            this.imgToggle = false;
            this.makeToggleActive = false;
          } else {
            this.makeToggleActive = false;
            this.imgToggle = true;
          }
        }, 200);
        this.appCommonService.sideNavState$.next(this.sideNavState);
      });
  }

  closed(url: string) {
    this.router.url.split("/")[1] !== url.split("/")[1] &&
      this.sharedService.display(true);
    this.collap.emit(this.collapsed);
    // For Removing the Selected Users when moving to another module without unselecting the users
    this.sharedService.deleteUsers = [];
  }

  getModules() {
    const routingList = this.appCommonService.getRoutingData();
    this.modules = [];
    this.myModules.forEach((module) => {
      module.modulename = module.modulename.toLowerCase();
      const filteredModule = routingList.filter(
        (routingData) =>
          routingData.module_name.toLowerCase() === module.modulename
      );
      if (filteredModule.length > 0) {
        let isAllowed = false;
        switch (module.modulename) {
          case "dashboard":
          // case 'user management':
          case "manage users":
          case "vendors":
          case "onboarding":
          case "associates":
          case "flagging":
          case "reports":
          case "settings":
            if (module.resources.length > 0) {
              module.resources.forEach((resource) => {
                if (resource.permissions.length > 0) {
                  isAllowed = true;
                }
              });
            }
            break;
        }
        if (isAllowed) {
          this.modules.push(filteredModule[0]);
        }
      }
    });
    this.modules.sort((a, b) => a.order - b.order);
  }

  checkActiveRoute(identifier) {
    if (this.role !== "superadmin" && this.role !== "SuperAdminRead") {
      this.modules = this.modules.filter(
        (ele) => ele.module_name !== "Vendors"
      );
    }
    const active = this.router.url.indexOf(identifier) === -1 ? false : true;
    return active;
  }

  ngOnDestroy() {
    if (this.stateSubscription) {
      this.stateSubscription.unsubscribe();
    }
  }
}
