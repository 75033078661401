import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { PrivacySettingsComponent } from "./privacy-settings.component";
import { ChangeLogsComponent } from "./change-logs/change-logs.component";
import { PrepareLogsComponent } from "./change-logs/prepare-logs/prepare-logs.component";
import { PreparePrivacyComponent } from "./prepare-privacy/prepare-privacy.component";

const routes: Routes = [
  { path: "privacy", component: PrivacySettingsComponent },
  { path: "editPrivacy/1", component: PreparePrivacyComponent },
  {
    path: "change-logs",
    component: ChangeLogsComponent,
  },
  { path: "prepare-logs", component: PrepareLogsComponent },
  { path: "editLog/:userId", component: PrepareLogsComponent },
  //     {

  //     path: "",
  //     component: PrivacySettingsComponent,
  //     children: [
  //       { path: "", redirectTo: "privacy", pathMatch: "full" },
  //       { path: "privacy", component: PrivacySettingsComponent },
  //       { path: "change-logs", component: ChangeLogsComponent },
  //     ],
  //   },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PrivacySettingsRoutingModule {}
