import { Component, OnInit, OnDestroy } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { Router } from "@angular/router";

import {
  RoleAuth,
  UserDetails,
  SortableColums,
  PaginationClass,
  PaginationControl,
} from "../../../utils/models";
import { UsersService } from "../users.service";
import {
  ConfirmationComponent,
  StatusComponent,
} from "../../../../app/utils/components";
import { SharedService } from "src/app/utils/services";
import { SessionStorage } from "ngx-webstorage";
import { Subscription } from "rxjs";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MatDialog } from "@angular/material/dialog";

@Component({
  selector: "app-manage-users",
  templateUrl: "./manage-users.component.html",
  styleUrls: ["./manage-users.component.scss"],
})
export class ManageUsersComponent implements OnInit, OnDestroy {
  @SessionStorage("mod") public modules: any;
  @SessionStorage("auth") public user: any;
  public manageUserForm: UntypedFormGroup;
  public filterForm: UntypedFormGroup;
  public permissions = new RoleAuth([]);
  public pageData = new PaginationClass({
    pageSize: 10,
    sort: { updatedAt: -1 },
  });
  public pageControl = new PaginationControl();
  public sortDirection = new SortableColums();
  public deletedSuccess = false;
  public allUserList = [];
  public allRoles = [];
  // public showToaster = false;
  // public successMessage;
  // public errorMessage;
  public recentUsers = [];
  public deletableUsers = [];
  public vendorCollections = [];
  public vendorArray = [];
  public vendor;
  public selectedPage = false;
  public deleteCount;
  public selectedCount = [];
  public toggleAll = false;
  public deleteUsers = [];
  public isChecked = false;
  public failedList: any;
  hoveredIndex: number | null = null;
  public role;
  checkedState: boolean[];
  public currentFilter = "role";
  public selectAll = false;
  public contentLoaded = false;
  private searchSubscription: Subscription;
  color = "accent";
  message: any;
  subscription: Subscription;
  searchTextLocal = "";

  public count = 0;
  public sortName = "";
  public order = 1;
  constructor(
    private dialog: MatDialog,
    private formBuilder: UntypedFormBuilder,
    private snackBar: MatSnackBar,
    private router: Router,
    private usersService: UsersService,
    private sharedService: SharedService
  ) {
    this.sharedService.insideAddUser.emit(false);
    if (!this.user) {
      router.navigateByUrl("/login");
    }
    this.manageUserForm = this.formBuilder.group({
      searchText: [""],
      selectAll: [false],
      allUser: this.formBuilder.array([[]]),
    });

    this.filterForm = this.formBuilder.group({
      role: [""],
      recent_user: [""],
    });
    this.sharedService.isDelete.subscribe((val) => {
      if (!val) {
      }
    });
    this.subscription = this.usersService.getMessage().subscribe((message) => {
      this.selectedPage = false;
      this.message = message;
      this.getAllUsers();
    });

    this.sharedService.delteUserEmitter.subscribe((res) => {
      this.contentLoaded = false;
      if (res.deleted) {
        this.isChecked = false;
        this.selectAll = false;
        this.getAllUsers();
        this.sharedService.openSnackBar({
          success: `${res.count} user${
            res.count > 1 ? "s" : ""
          } has been deleted successfully.`,
        });
        this.sharedService.deleteUsers = [];
        this.sharedService.getApprovalCount.next(true);
      } else if (res.failed) {
        this.sharedService.openSnackBar({ error: res.error.error.message });
        this.contentLoaded = true;
      }
    });
  }

  ngOnInit() {
    this.sharedService.display(false);
    this.sharedService.inUser = true;
    this.sharedService.inEditUser = false;
    this.sharedService.inAddUser = false;
    this.sharedService.inAddRole = false;
    this.sharedService.inEditRole = false;
    this.pageData.status = "all";
    this.pageData.vendor = "all";
    this.usersService.setLoadManageComp();
    this.role = this.user.userDetails.role;
    this.sharedService.fileUpload.subscribe((res) => {
      if (res) {
        this.contentLoaded = false;
        this.getAllUsers();
      }
    });
    this.checkModulePermissions();
  }

  ngOnDestroy() {
    if (this.searchSubscription) {
      this.searchSubscription.unsubscribe();
    }
  }

  /* check module permissions */
  checkModulePermissions() {
    if (this.modules) {
      const currentModule = this.modules.filter(
        (module) =>
          module.modulename.toLowerCase() === "manage users" &&
          module.resources.filter(
            (resource) => resource.resourcename.toLowerCase() === "users"
          ).length > 0
      );
      if (currentModule.length > 0) {
        let currentResource = currentModule[0].resources.filter(
          (resource) => resource.resourcename.toLowerCase() === "users"
        );
        this.permissions = new RoleAuth(currentResource[0].permissions);
        if (this.permissions.read) {
          this.pageData.searchText = "";
          this.getAllUsers(true);
          this.getAllRoles();
          this.usersService.setSubscription("btn-status", {
            addUser: this.permissions.create,
          });
          this.usersService.setSubscription("search", {
            addUser: this.permissions.create,
          });
          this.listenToEvents();
        } else {
          const nextResource = currentModule[0].resources.filter(
            (resource) => resource.resourcename.toLowerCase() === "roles"
          );
          const permissions = new RoleAuth(nextResource[0].permissions);
          if (permissions.read) {
            this.router.navigateByUrl("/manage-users/roles");
          }
        }
      }
    }
  }

  listenToEvents() {
    this.searchSubscription = this.usersService
      .getUserSearchText()
      .subscribe((res) => {
        if (
          res &&
          res.hasOwnProperty("users") &&
          res.hasOwnProperty("searchText")
        ) {
          this.searchTextLocal = res.searchText ? res.searchText : "";
          this.pageData.searchText = res.searchText;
          this.pageData.pageNumber = 1;
          this.getAllUsers();
        }
      });
  }

  /* get the list of roles */
  getAllRoles(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.usersService.get("roles").subscribe(
        (response) => {
          if (response.success === true && response.hasOwnProperty("payload")) {
            this.allRoles = response.payload;
          }
          resolve(1);
        },
        (error) => {
          reject(1);
        }
      );
    });
  }

  /* get all users */
  getAllUsers(spliceRecentData?: boolean, hasFilter?: boolean) {
    if (this.message) {
      if (
        (this.message == "all" && this.usersService.user == "userAll") ||
        this.message == "active" ||
        this.message == "inactive" ||
        this.message == "pending"
      ) {
        if (!this.selectedPage) {
          this.pageData.pageNumber = 1;
        }
        this.message = this.message == "all" ? "" : this.message;
        this.pageData.status = this.message;
      } else {
        if (!this.selectedPage) {
          this.pageData.pageNumber = 1;
        }
        this.message = this.message == "all" ? "" : this.message;
        this.pageData.vendor = this.message;
      }
    } else {
      this.pageData.status = "";
      this.pageData.vendor = "";
    }
    if (this.searchTextLocal) {
      this.pageData.searchText = this.searchTextLocal;
    }
    if (this.deletedSuccess) {
      this.pageData.searchText = "";
    }
    this.pageData.filter = {};
    if (hasFilter && this.currentFilter.trim() !== "") {
      this.pageData.filter[this.currentFilter] = this.filterForm.value.role;
    }
    this.usersService.post("users/find", this.pageData).subscribe(
      (response) => {
        if (response.success === true) {
          this.allUserList = [];
          this.selectAll = true;
          this.deletedSuccess = false;
          response.payload.users.forEach((user) => {
            // check if the user is already in the deletable list and set the flag accordingly
            user["isDelete"] =
              this.deletableUsers.filter(
                (usr) => usr.toLowerCase() === user.userid.toLowerCase()
              ).length > 0
                ? true
                : false;
            this.selectAll = user["isDelete"] ? this.selectAll : false;
            this.allUserList.push(new UserDetails(user));
            this.checkedState = Array(this.allUserList.length).fill(false);
          });
          this.pageData.totalItems = response.payload.totalUsers;
          if (spliceRecentData) {
            this.recentUsers = this.allUserList.slice(0, 5);
          }
          this.sharedService.userList.next(this.allUserList);
          setTimeout(() => (this.contentLoaded = true), 500);
        } else {
          this.resetPage();
        }
      },
      () => {
        this.resetPage();
      }
    );
  }
  sortColumn(event) {
    if (this.sortName != event.field || this.order != event.order) {
      this.sortName = event.field;

      this.count++;
      if (this.count % 2 != 0) {
        this.order = 1;
      } else {
        this.order = -1;
      }
      this.pageData.sort = {
        [event.field]: this.order,
      };
      this.getAllUsers();
    }
  }
  fiterUsers(filterType) {
    this.currentFilter = filterType;
    this.getAllUsers(false, true);
  }

  selectUser() {}

  /* reset data */
  resetPage() {
    this.contentLoaded = true;
    this.allUserList = [];
    this.pageData.totalItems = 0;
  }

  onHover(i) {
    this.hoveredIndex = i;
  }
  clear() {
    this.hoveredIndex = null;
  }

  /* retain the required data before navigation */
  navigatetoEditPage(userid) {
    this.usersService.setSubscription("btn-status", { addUser: false });
    this.usersService.setSubscription("search", { addUser: false });
    this.router.navigateByUrl(`/manage-users/users/update-user/${userid}`);
  }

  /* filter users based on search input */
  filterUser(searchText) {
    this.pageData.searchText = searchText;
    this.pageData.pageNumber = 1;
    this.selectAll = false;
    this.getAllUsers();
  }

  /* sort user based on the 'key' passed */
  sortBy(key: string) {
    this.pageData.sort = {};
    // ascending = 1, descending = -1
    this.sortDirection[key] = this.sortDirection[key] === 1 ? -1 : 1;
    this.pageData.sort[key] = this.sortDirection[key];
    this.getAllUsers();
  }

  /* set current page number */
  setCurrentPageNumber(event) {
    this.contentLoaded = false;
    this.selectedPage = true;
    this.pageData.pageNumber = event;
    this.getAllUsers();
    this.selectAll = false;
    // this.isUserSelected = false;
    // this.onCheckboxChange(-1);
  }

  /* uncheck all selected users on cancel */
  onCancel() {
    this.deletableUsers = [];
    this.allUserList.forEach((user) => (user.isDelete = false));
  }

  deleteUser(type) {
    if (type == "all") {
      this.toggleAll = !this.toggleAll;
      this.checkedState = Array(this.allUserList.length).fill(this.toggleAll);
      // if (this.toggleAll) {
      //   this.checkedState = Array(this.allUserList.length).fill(true);
      // } else {
      //   this.checkedState = Array(this.allUserList.length).fill(false);
      // }
    }

    // this.deleteUsers = this.allUserList.filter(
    //   (_, i) => this.checkedState[i]
    // );
    // if (this.deleteUsers.length > 0) {
    //   this.deleteCount = this.deleteUsers.length;
    //   this.isChecked = true;
    // } else {
    //   this.isChecked = false;
    // }
    this.sharedService.deleteUsers = this.allUserList.filter(
      (_, i) => this.checkedState[i]
    );
  }
}
