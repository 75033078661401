// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.change-pwd-dialog {
  padding: 0;
  min-width: 60vw;
}`, "",{"version":3,"sources":["webpack://./src/app/bas/my-profile/dialog/dialog.component.scss"],"names":[],"mappings":"AAAA;EACI,UAAA;EACA,eAAA;AACJ","sourcesContent":[".change-pwd-dialog {\n    padding: 0;\n    min-width: 60vw;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
