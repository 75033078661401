import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { HttpClientModule } from "@angular/common/http";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgSelectModule } from "@ng-select/ng-select";
import { AppRoutingModule } from "./app-routing.module";
import { AppCommonModule } from "./common/app-common.module";
import { AppComponent } from "./app.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgxWebstorageModule } from "ngx-webstorage";
import { MatSidenavModule } from "@angular/material/sidenav";
import { ManageUsersComponent } from "./bas/users/manage-users/manage-users.component";
import { UsersLayoutComponent } from "./bas/users/users-layout/users-layout.component";
import { NgxPaginationModule } from "ngx-pagination";
import { SortnamePipe } from "./sortname.pipe";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { MatCardModule } from "@angular/material/card";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from "ng-recaptcha";
import { environment } from "src/environments/environment";
import { NgxSkeletonLoaderModule } from "ngx-skeleton-loader";
import { ApprovalsV2Component } from "./bas/dashboard/approvals-v2/approvals-v2.component";

@NgModule({
  declarations: [AppComponent, SortnamePipe],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    NgSelectModule,
    AppCommonModule,
    HttpClientModule,
    NgxPaginationModule,
    NgxWebstorageModule.forRoot(),
    MatSidenavModule,
    MatCardModule,
    NgbModule,
    MatProgressBarModule,
    RecaptchaV3Module,
    NgxSkeletonLoaderModule.forRoot(),
  ],
  providers: [
    ApprovalsV2Component,
    ManageUsersComponent,
    UsersLayoutComponent,
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useValue: environment.siteKey,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
