import { Component, ViewChild } from "@angular/core";
import { UntypedFormGroup, FormBuilder, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { CKEditorComponent } from "ng2-ckeditor";
import { SettingsService } from "../../../../../app/bas/settings/settings.service";
import { ConfirmationComponent } from "../../../../../app/utils/components";
import { SharedService } from "../../../../../app/utils/services";
import * as ClassicEditor from "@ckeditor/ckeditor5-build-classic";

@Component({
  selector: "bas-prepare-logs",
  templateUrl: "./prepare-logs.component.html",
  styleUrls: ["./prepare-logs.component.scss"],
})
export class PrepareLogsComponent {
  public Editor = ClassicEditor;
  mycontent: string = "";
  public id;
  public logForm: UntypedFormGroup;
  log: string = "";
  @ViewChild("myckeditor") ckeditor: CKEditorComponent;
  constructor(
    private router: Router,
    private dialog: MatDialog,
    private formBuilder: FormBuilder,
    private settingsService: SettingsService,
    public shared: SharedService
  ) {
    this.logForm = this.formBuilder.group({
      version: ["", [Validators.required]],
      description: ["", [Validators.required]],
    });
  }

  ngOnInit() {
    this.shared.logEdit(false);
    this.shared.logCreate(false);
    this.id = this.settingsService.logId;
    if (this.id) {
      this.getEditDetails();
    }
    this.mycontent;
  }

  confirmAdd() {
    const dialogRef = this.dialog.open(ConfirmationComponent, {
      autoFocus: false,
      disableClose: true,
      height: "200px",
      panelClass: "confirm-delete-dialog",
      backdropClass: "confirm-delete-backdrop",
      data: {
        title: this.id ? "Edit Log" : `Add Log`,
        message: `Are you sure to ${this.id ? "edit" : "add"} Log ?`,
        buttonLableSubmit: "Confirm",
      },
    });

    dialogRef.afterClosed().subscribe((status: Boolean) => {
      if (status) this.id ? this.editLog() : this.save();
    });
  }

  getEditDetails() {
    this.settingsService.get(`changelog/${this.id}`).subscribe((res) => {
      this.logForm.patchValue({
        version: res.payload.version,
        description: res.payload.description,
      });
      this.mycontent = res.payload.updatesandfixes;
    });
  }

  editLog() {
    let logData: any = {
      version: this.logForm.value.version,
      description: this.logForm.value.description,
      updatesandfixes: this.mycontent,
    };
    this.settingsService.put(`changelog/${this.id}`, logData).subscribe(
      (res) => {
        if (res.success) this.handleSuccessResponse(res);
      },
      (err) => {
        this.showError(err);
      }
    );
  }

  save() {
    let logData: any = {
      version: this.logForm.value.version,
      description: this.logForm.value.description,
      updatesandfixes: this.mycontent,
    };
    this.settingsService.post("changelog", logData).subscribe(
      (res) => {
        if (res.success) this.handleSuccessResponse(res);
      },
      (err) => {
        this.showError(err);
      }
    );
  }

  handleSuccessResponse(res: any) {
    this.shared.openSnackBar({ success: res.message });
    this.router.navigateByUrl("/privacy-setting/change-logs");
  }

  onCancel() {
    this.router.navigateByUrl("/privacy-setting/change-logs");
  }

  showError(error: any) {
    this.shared.openSnackBar({
      error: error.error?.message || window["serverError"],
    });
  }
}
