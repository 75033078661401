import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validator, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { MyProfileService } from "../my-profile.service";
import { patternValidators } from "src/app/utils/validators";
import { SessionStorage, SessionStorageService } from "ngx-webstorage";
import { RoleAuth } from "src/app/utils/models";
import { SharedService } from "src/app/utils/services";
import { AppCommonService } from "src/app/common/app-common.service";

@Component({
  selector: "bas-change-password-v2",
  templateUrl: "./change-password-v2.component.html",
  styleUrls: ["./change-password-v2.component.scss"],
})
export class ChangePasswordV2Component implements OnInit {
  @SessionStorage("auth") private authToken;
  @SessionStorage("uId") private viewableUser: any;
  @SessionStorage("pStatus") public isResetPassword: any;
  @SessionStorage("mod") public modules: any;
  @SessionStorage("mobileForOtp") public mobileForOtp: any;
  @SessionStorage("whichPage") public whichPage: any;
  @SessionStorage("otp") public otp: any;
  public changePasswordForm: FormGroup;
  public permissions = new RoleAuth([]);
  public isInitialReset = false;
  public showOldPassword = false;
  public showNewPassword = false;
  public showConfirmPassword = false;
  public canNavigate = true;
  private mobile;

  constructor(
    public formBuilder: FormBuilder,
    private myProfileService: MyProfileService,
    public sharedService: SharedService,
    private router: Router,
    private appCommonService: AppCommonService,
    private sessionStorageService: SessionStorageService
  ) {
    if (!this.authToken) {
      this.router.navigateByUrl("/login");
    }

    this.changePasswordForm = this.formBuilder.group({
      mobile: [
        "",
        [
          Validators.required,
          Validators.pattern(patternValidators().mobileNumberRegExp),
        ],
      ],
    });
  }

  ngOnInit() {
    this.sharedService.changePassword(false);

    this.sharedService.inChangePassword.emit(true);
    this.changePasswordForm.patchValue({
      mobile: this.authToken.userDetails.mobile,
    });
  }

  /**
   * @param unMatchCtrl - actual field
   * @param actualCtrl - actual field
   * @param matchCtrl - field to be matched with actual field
   */

  /* service call for password update */
  upadatePassword() {
    this.sharedService.display(true);
    this.appCommonService.setData(
      "mobile",
      this.changePasswordForm.value.mobile
    );
    this.mobileForOtp = this.changePasswordForm.value.mobile;
    let body = this.changePasswordForm.value;
    this.sharedService.whichPage = "change-password";
    this.whichPage = "change-password";
    this.mobile = this.mobileForOtp;

    let changePasswordData = {
      mobile: this.changePasswordForm.value.mobile,
      type: "Change password",
    };

    this.myProfileService.post("send/otp", changePasswordData).subscribe(
      (response) => {
        let mobile = this.authToken.userDetails.mobile;
        if (response.success) {
          this.myProfileService
            .post(`session/logout?mobile=${mobile}`, {})
            .subscribe((res) => {});
          this.sessionStorageService.clear();
          sessionStorage.clear();
          this.mobileForOtp = this.mobile;
          this.whichPage = "change-password";
          this.otp = response.payload.otp;
          let number =
            "*****" + this.changePasswordForm.value.mobile.substring(6, 10);
          this.sharedService.openSnackBar({
            success: `OTP has been sent to "${number}"`,
          });
          this.router.navigateByUrl("/otp-verify");
        } else {
          this.sharedService.openSnackBar({
            error: "Error while updating password.",
          });
        }
        this.sharedService.display(false);
      },
      (error) => {
        this.sharedService.openSnackBar({
          error: error.error?.message || window["serverError"],
        });
        this.sharedService.display(false);
      }
    );
  }
}
