import {
  Component,
  EventEmitter,
  NgModule,
  OnInit,
  Output,
} from "@angular/core";
import {
  Router,
  ActivatedRoute,
  UrlTree,
  UrlSegmentGroup,
  PRIMARY_OUTLET,
  UrlSegment,
} from "@angular/router";
import {
  RoleAuth,
  UserDetails,
  SortableColums,
  PaginationClass,
  PaginationControl,
} from "../../../utils/models";
import { UsersService } from "../../users/users.service";
import { DashboardService } from "../dashboard.service";
import { ConfirmationComponent } from "src/app/utils/components/confirmation/confirmation.component";
import { SharedService } from "src/app/utils/services";
import { SessionStorage } from "ngx-webstorage";
import { FormBuilder, FormGroup } from "@angular/forms";
import { VendorsService } from "../../vendors/vendors.service";
import { Subject, Subscription } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";

@Component({
  selector: "bas-approvals-v2",
  templateUrl: "./approvals-v2.component.html",
  styleUrls: ["./approvals-v2.component.scss"],
})
export class ApprovalsV2Component implements OnInit {
  @SessionStorage("mod") public userModules: any;
  @SessionStorage("auth") public user: any;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();
  public userForm: FormGroup;
  public permissions = new RoleAuth([]);
  public pageData = new PaginationClass({ pageSize: 10 });
  public pageControl = new PaginationControl();
  public sortDirection = new SortableColums();
  public searchForm: FormGroup;
  public allApprovalList = [];
  public emptyList = [];
  public deletableUsers = [];
  public vendorCollections = [];
  public failedList: any;
  public vendor: string;
  public role;
  public superadmin = false;
  public Vendoradmin = false;
  public selectAll = false;
  public routeSegmentId: string;
  public showBack = false;
  message: any;
  posts;
  subscription: Subscription;
  constructor(
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private router: Router,
    private dashboardService: DashboardService,
    private vendorService: VendorsService,
    private sharedService: SharedService,
    public activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder
  ) {
    this.searchForm = this.formBuilder.group({
      searchText: [""],
    });
    this.sharedService.approvalFileUpload.subscribe((res) => {
      if (res) {
        this.getAllUsers();
      }
    });
    this.subscription = this.dashboardService
      .getMessage()
      .subscribe((message) => {
        this.message = message;
        this.pageData.vendor = this.message;
        this.sharedService.display(true);
        this.pageData.searchText = this.searchForm.value.searchText;
        this.dashboardService.post("users/approval", this.pageData).subscribe(
          (response) => {
            if (response.success === true) {
              this.allApprovalList = response.payload.users;
              this.dtTrigger.next(null);
              this.pageData.totalItems = response.payload.totalUsers;
            }
            this.sharedService.display(false);
          },
          (err) => {
            this.allApprovalList = [];
            this.sharedService.display(false);
            this.resetPage();
          }
        );
      });
  }

  ngOnInit() {
    this.role = this.user.userDetails.role;
    if (this.role == "superadmin") {
      this.superadmin = true;
    } else if (this.role == "Vendoradmin") {
      this.Vendoradmin = true;
    }
    Promise.all([this.getAllVendors()]).then(() => {
      this.getAllUsers();
    });
    const urlTree: UrlTree = this.router.parseUrl(this.router.url);
    const segmentGroup: UrlSegmentGroup = urlTree.root.children[PRIMARY_OUTLET];
    const urlSegment: UrlSegment[] = segmentGroup.segments;
    this.routeSegmentId = urlSegment[urlSegment.length - 1].path;
    if (this.userModules) {
      const currentModule = this.userModules.filter(
        (module) =>
          module.modulename.toLowerCase() === "dashboard" &&
          module.resources.filter(
            (resource) => resource.resourcename.toLowerCase() === "approvals"
          ).length > 0
      );
      if (currentModule.length > 0) {
        const dashBoardModule = this.userModules.filter(
          (module) =>
            module.modulename.toLowerCase() === "dashboard" &&
            module.resources.filter(
              (resource) => resource.resourcename.toLowerCase() === "dashboard"
            ).length > 0
        );
        this.showBack =
          dashBoardModule.length > 0 &&
          dashBoardModule[0].resources[0].permissions.length > 0
            ? true
            : false;
        const currentResource = currentModule[0].resources.filter(
          (resource) => resource.resourcename.toLowerCase() === "approvals"
        );
        this.permissions = new RoleAuth(currentResource[0].permissions);
        if (this.permissions.read) {
          if (
            this.dashboardService.resumePage.approvals.searchText.trim() !== ""
          ) {
            this.filterRole(
              this.dashboardService.resumePage.approvals.searchText
            );
            this.dashboardService.resumePage.approvals.searchText = "";
          }
        }
      }
    }
    this.setTableOption();
  }

  setTableOption() {
    if (this.routeSegmentId === "approval") {
      this.dtOptions = {
        retrieve: true,
        paging: true,
        searching: true,
        lengthChange: true,
        ordering: true,
        pagingType: "numbers",
        pageLength: 10,
        processing: true,
        lengthMenu: [5, 10, 25],
      };
    } else {
      this.dtOptions = {
        paging: true,
        info: false,
        lengthChange: false,
        searching: true,
        ordering: true,
        pagingType: "numbers",
        pageLength: 4,
        processing: true,
        lengthMenu: [5, 10, 25],
      };
    }
  }

  vendorSelect(data) {
    this.vendor = data;
    if (data === "all") {
      this.vendor = undefined;
    }
    this.pageData.pageNumber = 1;
    this.pageData.totalItems = 0;
    this.getAllUsers();
  }

  getAllVendors() {
    this.sharedService.display(true);
    this.vendorService.post("vendors", this.pageData).subscribe(
      (res) => {
        res.message.Vendors.map((ele) => {
          this.vendorCollections.push(ele);
        });
      },
      (err) => {
        this.resetPage();
      }
    );
  }

  filterRole(searchText: string) {
    this.pageData.searchText = searchText;
    this.pageData.pageNumber = 1;
    this.pageData.totalItems = 0;
    this.getAllUsers();
  }
  onSearch(evnt, canEmit?: boolean) {
    if (
      (evnt &&
        ((evnt.keyCode && evnt.keyCode === 13) ||
          evnt.target.value.trim() === "")) ||
      canEmit
    ) {
      this.getAllUsers();
    }
  }
  /* get all users */
  getAllUsers(hideError?: boolean) {
    this.pageData.vendor = this.vendor;
    this.sharedService.display(true);

    this.pageData.searchText = this.searchForm.value.searchText;

    this.dashboardService.post("users/approval", this.pageData).subscribe(
      (response) => {
        if (response.success === true) {
          this.allApprovalList = response.payload.users;
          this.allApprovalList.forEach((element) => {
            let match = this.vendorCollections.find(
              (val) => val._id == element.vendor
            );
            if (match) {
              element.vendor = match.organizationname;
            }
          });
        }
        this.pageData.totalItems = response.payload.totalUsers;
        this.dtTrigger.next(null);

        this.sharedService.display(false);
      },
      (err) => {
        this.allApprovalList = [];
        this.sharedService.display(false);
        this.resetPage();
      }
    );
  }

  /* reset data */
  resetPage() {
    this.allApprovalList = [];
    this.pageData.totalItems = 0;
  }

  /* when the checkbox value changes */
  onCheckboxChange(userIndex: number, $event?: any) {
    const allUserCount = this.allApprovalList.length;
    if (userIndex < 0) {
      this.allApprovalList.forEach((user) => {
        user.isDelete = this.selectAll;
        if (this.selectAll) {
          if (this.deletableUsers.indexOf(user.userid.toLowerCase()) < 0) {
            this.deletableUsers.push(user.userid.toLowerCase());
          }
        } else {
          this.deletableUsers = this.deletableUsers.filter(
            (st) => st.toLowerCase() !== user.userid.toLowerCase()
          );
        }
      });
    } else if (allUserCount > 0) {
      if (this.allApprovalList[userIndex].isDelete) {
        this.deletableUsers.push(
          this.allApprovalList[userIndex].userid.toLowerCase()
        );
      } else {
        this.deletableUsers = this.deletableUsers.filter(
          (usr) =>
            usr.toUpperCase() !==
            this.allApprovalList[userIndex].userid.toUpperCase()
        );
      }
      this.selectAll =
        this.allApprovalList.filter((user) => user.isDelete === true).length ===
        allUserCount
          ? true
          : false;
    }
  }

  approveOrReject(isApprove: boolean, user: string) {
    let userData = {
      email: user["email"],
      mobile: user["mobile"],
    };
    if (isApprove) {
      userData["approved"] = true;
    } else {
      userData["rejected"] = true;
    }

    this.sharedService.display(true);
    this.dashboardService.post("user/approve", userData).subscribe(
      (response) => {
        if (response.success) {
          this.sharedService.openSnackBar({
            success: response.message || "User Approved Successfully",
          });
          this.getAllUsers(true);
        }
        this.sharedService.display(false);
        this.sharedService.getApprovalCount.next(true);
      },
      (err) => {
        this.sharedService.display(false);
        this.sharedService.openSnackBar({
          error: err.error?.message || "Server Error",
        });
      }
    );
  }

  confirmApproveOrReject(isApprove: boolean, user: string) {
    let action = isApprove ? "Approve" : "Reject";

    const dialogRef = this.dialog.open(ConfirmationComponent, {
      autoFocus: false,
      disableClose: true,
      height: "200px",
      panelClass: "confirm-delete-dialog",
      backdropClass: "confirm-delete-backdrop",
      data: {
        title: action,
        message: `Are you sure to ${action} "${user["firstname"]} ${user["lastname"]}" ?`,
        userData: user,
        buttonLableSubmit: "Yes",
        buttonLableCancel: "No",
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.approveOrReject(isApprove, user);
      } else {
        this.getAllUsers();
      }
    });
  }

  setCurrentPageNumber(event) {
    this.pageData.pageNumber = event;
    this.getAllUsers();
    this.selectAll = false;
  }

  dashboard() {
    this.router.navigate(["dashboard"]);
  }
}
