import {
  Component,
  OnInit,
  ChangeDetectorRef,
  OnDestroy,
} from "@angular/core";
import {
  ShowModuleResources,
  RoleAuth,
  PaginationClass,
} from "src/app/utils/models";
import * as Papa from "papaparse";
import { Router } from "@angular/router";
import { UsersService } from "../users.service";
import { SessionStorage } from "ngx-webstorage";
import { Subscription } from "rxjs";
import { UntypedFormGroup, UntypedFormBuilder } from "@angular/forms";
import { SharedService } from "src/app/utils/services";
import { VendorsService } from "../../vendors/vendors.service";
import { ManageUsersComponent } from "../manage-users/manage-users.component";
import { DashboardService } from "../../dashboard/dashboard.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MatDialog } from "@angular/material/dialog";
import { StatusComponentComponent } from "src/app/utils/components/status-component/status-component.component";
import { ConfirmationComponent } from "src/app/utils/components";

@Component({
  selector: "app-layout",
  templateUrl: "./users-layout.component.html",
  styleUrls: ["./users-layout.component.scss"],
})
export class UsersLayoutComponent implements OnInit, OnDestroy {
  @SessionStorage("mod") public userModules: any;
  @SessionStorage("auth") public user: any;
  public permissions = new RoleAuth([]);
  public showResources = new ShowModuleResources({});
  public usersSubscription: Subscription;
  public searchSubscription: Subscription;
  public pageData = new PaginationClass({
    pageSize: 10,
    sort: { createddate: -1 },
  });
  public displayRight = false;
  public searchForm: UntypedFormGroup;
  public selectedTabLabel = "Users";
  public vendorCollections = [];
  public selectedVendor;
  public selectedUser;
  public vendor;
  public userSelected;
  public failedList;
  public flag = true;
  public filesData: File;
  public role;
  public display = false;
  public allBtns = {
    add: false,
    addUser: false,
    bulkUserUpload: false,
    userReport: false,
    addRole: false,
    search: false,
  };

  constructor(
    private router: Router,
    public usersService: UsersService,
    public sharedService: SharedService,
    private dashboardService: DashboardService,
    public snackBar: MatSnackBar,
    public formBuilder: UntypedFormBuilder,
    private vendorService: VendorsService,
    public ManageUsersComponent: ManageUsersComponent,
    private dialog: MatDialog,
    public changeDetactor: ChangeDetectorRef
  ) {
    this.sharedService.display(false);
    this.searchForm = this.formBuilder.group({
      searchText: [""],
    });
    this.sharedService.insideAddUser.subscribe((res) => {
      this.displayRight = res;
    });
    this.sharedService.addUser.subscribe((data) => {
      if (data) {
        this.searchForm.patchValue({ searchText: "" });
      }
    });
    this.sharedService.userDelete.subscribe((data) => {
      this.display = data;
      if (this.display) {
        this.searchForm.patchValue({ searchText: "" });
        this.sharedService.isDelete.emit(false);
      }
    });
  }

  ngOnInit() {
    this.selectedVendor = "all";
    this.selectedUser = "all";
    this.sharedService.inAddRole = false;
    this.sharedService.inEditRole = false;
    this.sharedService.inAddUser = false;
    this.sharedService.inEditUser = false;
    this.sharedService.show = true;
    this.role = this.user.userDetails.role;
    this.getAllVendors();
    this.checkModulePermissions();
    this.usersService.getLoadManageComp().subscribe(() => {
      this.flag = true;
    });
  }

  ngOnDestroy() {
    this.searchForm.patchValue({ searchText: "" });
    if (this.searchSubscription) {
      this.searchSubscription.unsubscribe();
    }
  }

  getAllVendors() {
    this.vendorService.post("vendors", this.pageData).subscribe(
      (res) => {
        res.message.Vendors.map((ele) => {
          let obj = {
            orgName: ele.organizationname,
            id: ele._id,
          };
          this.vendorCollections.push(obj);
        });
      },
      (err) => {
        this.resetPage();
      }
    );
  }

  /* reset data */
  resetPage() {
    this.pageData.totalItems = 0;
  }

  vendorSelect(data) {
    this.usersService.sendMessage(data, "vendorAll");

    this.vendor = data;
    if (data === "all") {
      this.vendor = undefined;
    } else {
    }
  }

  userSelect(data) {
    this.usersService.sendMessage(data, "userAll");

    this.userSelected = data;
    if (data === "all") {
      this.userSelected = undefined;
    } else {
    }
  }

  /* check module permissions */
  checkModulePermissions() {
    if (this.userModules) {
      const userModule = this.userModules.filter(
        (module) => module.modulename.toLowerCase() === "manage users"
      );
      if (userModule.length > 0) {
        userModule[0].resources.forEach((resource) => {
          resource.resourcename = resource.resourcename.toLowerCase();
          switch (resource.resourcename.toLowerCase()) {
            case "users":
              if (
                resource.permissions.filter(
                  (permission) => permission.toLowerCase() === "read"
                ).length > 0
              ) {
                this.allBtns.userReport = this.showResources.users = true;
                this.allBtns.search = true;
              }
              if (
                resource.permissions.filter(
                  (permission) => permission.toLowerCase() === "create"
                ).length > 0
              ) {
                this.allBtns.bulkUserUpload = this.allBtns.addUser = true;
              }
              if (
                resource.permissions.filter(
                  (permission) => permission.toLowerCase() === "update"
                ).length > 0
              ) {
                this.allBtns.bulkUserUpload = true;
              }
              break;
            case "roles": {
              if (
                resource.permissions.filter(
                  (permission) => permission.toLowerCase() === "read"
                ).length > 0
              ) {
                this.showResources.roles = true;
              }
              if (
                resource.permissions.filter(
                  (permission) => permission.toLowerCase() === "create"
                ).length > 0
              ) {
                this.allBtns.addRole = true;
              }
              break;
            }
          }
        });
        if (this.showResources.users) {
          this.selectedTabLabel = "Users";
          // this.router.navigateByUrl("/manage-users/users");
        } else if (this.showResources.roles) {
          this.selectedTabLabel = "Roles";
          // this.router.navigateByUrl("/manage-users/roles");
        }
      }
    }
  }

  onTabClick(tabName) {
    this.selectedTabLabel = this.usersService.currentSelectedTab = tabName;
    this.searchForm.patchValue({ searchText: "" });
    this.sharedService.deleteUsers = [];
  }

  onSearch(evnt, canEmit?: boolean) {
    if (
      (evnt &&
        ((evnt.keyCode && evnt.keyCode === 13) ||
          evnt.target.value.trim() === "")) ||
      canEmit
    ) {
      let data = {
        searchText: this.searchForm.value.searchText,
      };
      data[this.selectedTabLabel.toLowerCase()] = true;
      this.usersService.setSubscription("search", data);
      this.usersService.sendUserSearchText(data);
    } else {
      this.usersService.setSubscription(
        "search",
        this.searchForm.value.searchText
      );
    }
  }

  navigatePage(action) {
    this.flag = false;
    this.allBtns[action] = false;
    this.allBtns.search = false;
    this.allBtns.add = true;
  }

  uploadExcel(event) {
    const allowedExtensions = /(\.csv)$/i;
    if (allowedExtensions.exec(event.target.files[0].name)) {
      this.filesData = event.target.files[0];
      const formData: FormData = new FormData();
      formData.append("files", this.filesData);

      this.sharedService.openSnackBar({
        success: "Uploading is in progress, please wait.",
      });
      this.vendorService.createUsers("user/upload", formData).subscribe(
        (response) => {
          if (response.success) {
            response.payload.failureCount
              ? this.showUploadStatus(response.payload.failureData)
              : this.sharedService.fileUpload.emit(true);
          }

          this.sharedService.openSnackBar({
            success: response.payload.message,
          });
          this.sharedService.getApprovalCount.next(true);
        },
        (err) => {
          if (err.status === 400)
            setTimeout(
              () =>
                this.sharedService.openSnackBar({
                  error: err.error.error.message,
                }),
              1000
            );
          else
            this.sharedService.openSnackBar({
              error: "Error while uploading sample CSV",
            });
        }
      );
    }
    event.target.value = "";
  }

  downloadSample() {
    const fileTypes = this.usersService.getFileType();
    let fileType = "csv";
    this.sharedService.display(true);
    this.usersService.downloadCSV(`sample/usersUpload.csv`).subscribe(
      (res) => {
        this.downloadFile(res, "sampleUser", fileTypes[fileType]);
        this.sharedService.display(false);
      },
      (error) => {
        this.sharedService.display(false);
        this.sharedService.openSnackBar({
          error: "Error while downloading sample CSV",
        });
      }
    );
  }

  downloadFile(response: any, filename: string, fileType: string) {
    const blob = new Blob([response], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.href = url;
    a.download = "sample.csv";
    document.body.appendChild(a);
    a.click();

    window.URL.revokeObjectURL(url);
  }

  filterPopUp() {
    let vendorValue = this.selectedVendor;
    let statusValue = this.selectedUser;
    if (vendorValue == "all") {
      vendorValue = "";
    }
    if (statusValue == "all") {
      statusValue = "";
    }
    let obj = {
      vendor: vendorValue,
      status: statusValue,
      searchText: this.searchForm.value.searchText,
    };
    this.sharedService.display(true);
    this.usersService.fileDownload("users/download", obj).subscribe(
      (res) => {
        const currentDate = new Date()
          .toString()
          .split(" GMT")[0]
          .replace(/:/g, "-");
        Papa.parse(res, {
          complete: (result) => {
            const csv = Papa.unparse(result.data);

            const blob = new Blob([csv], { type: "text/csv" });

            const link = document.createElement("a");
            link.setAttribute("href", window.URL.createObjectURL(blob));
            link.setAttribute("download", `user-${currentDate}.csv`);
            document.body.appendChild(link);
            link.click();

            document.body.removeChild(link);
          },
        });

        this.sharedService.display(false);
      },
      (err) => {
        this.sharedService.display(false);
        this.sharedService.openSnackBar({ error: "No Users Found" });
      }
    );
  }

  showUploadStatus(uploadStatus: any) {
    this.failedList = uploadStatus;
    this.sharedService.statusPage = "Users";
    const dialogRef = this.dialog.open(StatusComponentComponent, {
      autoFocus: false,
      disableClose: true,
      width: "auto",
      height: "auto",
      panelClass: "status-section-custom-dialog",
      backdropClass: "custom-backdrop",
      data: {
        category: "csv-upload",
        status: "Success",
        response: uploadStatus,
      },
    });
    dialogRef.afterClosed().subscribe((status) => {
      if (status === "download") this.downloadFailed();
      this.sharedService.fileUpload.emit(true);
    });
  }

  private downloadFailedCSV(response: any, filename: string) {
    const blob = new Blob([response], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.href = url;
    a.download = "failed.csv";
    document.body.appendChild(a);
    a.click();

    window.URL.revokeObjectURL(url);
  }

  downloadFailed() {
    this.usersService
      .failedUsers("users/download/failed", this.failedList.errors)
      .subscribe(
        (response) => {
          this.downloadFailedCSV(response, "failure-user-report.csv");
        },
        () => {
          this.sharedService.openSnackBar({
            error: "Error while downloading CSV",
          });
        }
      );
  }

  popupConfirm() {
    let deleteUser = this.sharedService.deleteUsers;

    let confirmMsg = `Are you sure to delete ${deleteUser.length} user${
      deleteUser.length > 1 ? "s" : ""
    }?`;

    const dialogRef = this.dialog.open(ConfirmationComponent, {
      autoFocus: false,
      disableClose: true,
      panelClass: "confirm-delete-dialog",
      backdropClass: "confirm-delete-backdrop",
      data: {
        title: "Delete Users",
        message: confirmMsg,
      },
    });

    dialogRef.afterClosed().subscribe((status: Boolean) => {
      if (status) {
        this.usersService
          .post("users/delete", {
            users: deleteUser.map((item) => item.userid),
          })
          .subscribe(
            (response) => {
              if (response.success === true) {
                this.sharedService.delteUserEmitter.next({
                  deleted: true,
                  count: deleteUser.length,
                });
              }
            },
            (error) => {
              this.sharedService.delteUserEmitter.next({ failed: true, error });
            }
          );
      }
    });
  }
}
