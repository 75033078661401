import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MyProfileService } from "../my-profile.service";
import { SharedService } from "src/app/utils/services";
import { Router } from "@angular/router";
import { patternValidators } from "src/app/utils/validators";
import { SessionStorage } from "ngx-webstorage";
import { Auth, PaginationClass } from "src/app/utils/models";
import { UsersService } from "../../users/users.service";
import { VendorsService } from "../../vendors/vendors.service";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";

@Component({
  selector: "bas-profile-v2",
  templateUrl: "./profile-v2.component.html",
  styleUrls: ["./profile-v2.component.scss"],
})
export class ProfileV2Component implements OnInit {
  public profileForm: FormGroup;
  @SessionStorage("auth") public authDetails;
  public pageData = new PaginationClass({ pageSize: 10 });
  public saveAuthDetails: any;
  public isDisabled = false;
  public isEditable = true;
  public vendorName;
  public allRolesList = [];
  public disable = true;
  public sites = [];
  public role;

  constructor(
    public formBuilder: FormBuilder,
    private myProfileService: MyProfileService,
    public sharedService: SharedService,
    private dialog: MatDialog,
    private router: Router,
    private snackBar: MatSnackBar,
    private usersService: UsersService,
    private vendorService: VendorsService
  ) {
    this.profileForm = this.formBuilder.group({
      firstname: ["", [Validators.required]],
      lastname: ["", [Validators.required]],
      email: ["", [Validators.pattern(patternValidators().emailIdRegExp)]],
      mobile: [
        "",
        [
          Validators.required,
          Validators.pattern(patternValidators().mobileNumberRegExp),
        ],
      ],
      role: ["", [Validators.required]],
      vendor: ["", [Validators.required]],
      reportingmanager: ["", [Validators.required]],
    });
    this.sharedService.madeChanges.subscribe((res) => {
      if (res) {
        this.bindUserDetails();
      }
    });
    this.saveAuthDetails = this.authDetails;
    if (
      this.authDetails.userDetails.role !== "superadmin" &&
      this.authDetails.userDetails.role !== "Vendoradmin"
    )
      this.isDisabled = true;
  }

  ngOnInit() {
    this.sharedService.vendorUpdate.subscribe((res) => {
      this.vendorName = res;
    });
    this.sharedService.inChangePassword.emit(false);
    this.role = this.authDetails.userDetails.role;
    this.sharedService.profileSites = false;
    this.sharedService.profileOverview = true;
    this.getAllRoles();
    this.bindUserDetails();
    this.sharedService.profileEdit.subscribe((res) => {
      this.isEditable = res;
    });
    this.sharedService.edit.emit(false);
  }

  editable() {
    this.isEditable = false;
  }

  onCancel() {
    this.bindUserDetails();
    this.isEditable = true;
  }

  bindUserDetails() {
    this.profileForm.patchValue(this.authDetails.userDetails);
    this.sharedService.isDirty = false;
    this.profileForm.patchValue({
      firstname: this.authDetails.userDetails.firstname,
      lastname: this.authDetails.userDetails.lastname,
      mobile: this.authDetails.userDetails.mobile,
      email: this.authDetails.userDetails.email,
      role: this.authDetails.userDetails.role,
      vendor: this.authDetails.userDetails.vendor.vendorId,
      reportingmanager:
        this.authDetails.userDetails.reporting_manager_details.mobile,
    });
    this.sharedService.setProfileData(this.profileForm.value);
  }

  onValueChange(value?) {
    if (value) {
      this.profileForm["role"] = value.value;
    }
    this.sharedService.isDirty = true;
    this.sharedService.setProfileData(this.profileForm.value);
  }
  getAllRoles(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.usersService.get("roles").subscribe(
        (response) => {
          if (response.success === true && response.hasOwnProperty("payload")) {
            this.allRolesList = response.payload;
          }
          resolve(1);
        },
        (error) => {
          reject(1);
        }
      );
    });
  }

  /* error handler - show error message in the snackbar */
  showError(error: any) {
    this.sharedService.display(false);
    this.sharedService.openSnackBar({
      error: error.error?.message || window["serverError"],
    });
  }
}
