import { Component } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { SessionStorage } from "ngx-webstorage";
import { ConfirmationComponent } from "src/app/utils/components";
import { patternValidators } from "src/app/utils/validators";

@Component({
  selector: "bas-delete-user",
  templateUrl: "./delete-user.component.html",
  styleUrls: ["./delete-user.component.scss"],
})
export class DeleteUserComponent {
  public storage;
  public changePasswordForm: FormGroup;
  public userDetails;
  @SessionStorage("auth") public auth: any;
  public token;
  public deleted = false;
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private dialog: MatDialog
  ) {
    if (!this.auth) {
      this.router.navigate(["/login"]);
    }
    this.changePasswordForm = this.formBuilder.group({
      mobile: [
        "",
        [
          Validators.required,
          Validators.pattern(patternValidators().mobileNumberRegExp),
        ],
      ],
    });
  }

  ngOnInit() {}
  deleteUser() {
    // this.httpService
    //   .delete(`user/${this.auth.userDetails.id}`)
    //   .subscribe((res) => {
    //     if (res) {
    this.deleted = true;
    //   }
    // });
  }
  confirmDelete() {
    let confirmMsg = `Are you sure to delete the user?`;
    const dialogRef = this.dialog.open(ConfirmationComponent, {
      autoFocus: false,
      disableClose: true,
      height: "200px",
      panelClass: "confirm-delete-dialog",
      backdropClass: "confirm-delete-backdrop",
      data: {
        title: "Save Changes",
        message: confirmMsg,
        buttonLableSubmit: "Confirm",
      },
    });

    dialogRef.afterClosed().subscribe((status: Boolean) => {
      if (status) {
        this.deleteUser();
      }
    });
  }
  upadatePassword() {}
  navigate() {
    this.router.navigate(["/login"]);
  }
}
